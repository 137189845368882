import { ReactNode } from 'react';
import { Divider } from '@geberit/gdds';
import { useSelector } from 'react-redux';

// styles
import ocbStyles from '../../Button/styles.module.scss';
import styles from './meta-navigation.module.scss';

// types
import type { MetaNavigationViewProps } from './types';

// components
import MetaNavigationLinks from './MetaNavigationLinks';
import OcbButton from '../../Button/OcbButton';
import DownloadCenterButton from '../../Button/dc-button';
import CiamLogin from './ciam-login';
import MetaNavigationAction from './MetaNavigationAction';
import { Translation } from '../../Translation/Translation';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

// utils
import { siteShortcutTrackingAction } from 'features/header/trackingActions';
import { webshopMaintenanceEnabledSelector } from 'utils/selectors/maintenanceSelectors';
import { useIsDesktop } from '../../App/SizeProvider';
import { shoppingBasketCountSelector } from 'utils/selectors/shoppingBasketSelector';
import { navigationMetaPreviewIdSelector } from 'utils/selectors/navigationSelectors';
import { useHeaderPin } from 'utils/hooks/use-header-pin';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useGroup } from 'utils/hooks/use-group';
import { webshopLandingpageUrlSelector } from 'utils/selectors/globalsSelectors';

function MetaNavigationView({
  metaNavigationItems,
  ciamEnabled,
  webshopEnabled,
  ocb,
  activeIcons,
  webshopBasketPageUrl,
  updateActiveIcon,
  isCampus,
  closeFlyout,
}: Readonly<MetaNavigationViewProps>) {
  const isGroup = useGroup();
  const isDesktop = useIsDesktop();
  const shoppingCartCount = useSelector(shoppingBasketCountSelector);
  const metaNavigationPreviewId = useSelector(navigationMetaPreviewIdSelector);
  const translate = useTranslationFunction();
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);
  const headerPin = useHeaderPin();
  const webshopLandingPageUrl = useSelector(webshopLandingpageUrlSelector);
  const webShopUrl = shoppingCartCount === 0 ? webshopLandingPageUrl : webshopBasketPageUrl;

  /**
   * Render Action icons for mobile and desktop with translation and count
   *
   * @param {Number} count
   * @param {String} type
   */
  const mobileTranslationWrapper = (count, type): ReactNode => {
    const countValue = !isDesktop ? `(${count})` : count;
    const countSpan = count > 0 && (
      <span className={styles.actionsLabel}> {countValue >= 99 ? 99 : countValue}</span>
    );

    if (!isDesktop) {
      return (
        <>
          <span>
            <Translation id={`web20_metanavigation_${type}`} />
          </span>
          {countSpan}
        </>
      );
    }

    return countSpan;
  };

  return (
    <nav className={styles.metaNavigationWrapper}>
      <div className="grid-container">
        <div className={styles.contentContainer}>
          {isDesktop && <LanguageSelector />}
          {!isCampus && (
            <MetaNavigationLinks links={metaNavigationItems} previewId={metaNavigationPreviewId} />
          )}
          <nav className={styles.actions}>
            {headerPin.enabled && headerPin.entries[0] && (
              <>
                <MetaNavigationAction
                  text={headerPin.entries[0].label}
                  url={headerPin.entries[0].url}
                  name="location"
                  activeIcons={activeIcons}
                  label={headerPin.entries[0].ariaLabel}
                />
                <Divider />
              </>
            )}
            {ciamEnabled && (
              <CiamLogin
                addActiveClass={(name) => updateActiveIcon(name)}
                classes={`${styles.link} ${activeIcons.includes('login') ? styles.active : ''}`}
              />
            )}
            {webshopEnabled && !webshopDowntime && !isCampus && (
              <MetaNavigationAction
                url={webShopUrl}
                name="shoppingcart"
                count={shoppingCartCount}
                mobileTranslationWrapper={mobileTranslationWrapper}
                activeIcons={activeIcons}
                trackingAction={siteShortcutTrackingAction('Basket')}
                label={translate('web20_shoppingcart_label')}
              />
            )}
            {isGroup && (
              <div className={ocbStyles.ocbWrapper}>
                <DownloadCenterButton closeFlyout={closeFlyout} />
              </div>
            )}
            {!isGroup && ocb && !isCampus && ocb.visible && (
              <div className={ocbStyles.ocbWrapper}>
                <OcbButton ocb={ocb} />
              </div>
            )}
          </nav>
        </div>
      </div>
    </nav>
  );
}

export default MetaNavigationView;
