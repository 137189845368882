import styled, { css, keyframes } from 'styled-components';
import { Button } from '@geberit/gdds';

// components
import { NavButton } from './nav-button';

// utils
import { media } from '../utils/no-touch-device';

export const MegaMenuContainer = styled.div(({ theme }) => {
  return css`
    background-color: ${theme.colors.white};

    .animated-logo {
      display: block;
    }

    @media ${theme.device.large} {
      animation-name: ${slideInAnimation};
      animation-duration: 1s;
      animation-timing-function: ease;

      margin-top: 4rem;
      .animated-logo {
        display: none;
        &.show-on-desktop {
          display: block;
          right: 0 !important;
        }
      }
    }
  `;
});

const slideInAnimation = keyframes`
0% {
  margin-top: 0;
}
100% {
  margin-top: 4rem;
}
`;

export const NavItemContainer = styled.li`
  ${() => {
    return css`
      width: 100%;
      padding: 0;
      list-style: none;

      &.spacer {
        margin-bottom: 1.5rem;
      }
    `;
  }}
`;

export type Animation = {
  animationPhase: 1 | 2;
  animationType?: 'deeper' | 'higher';
  withDelay?: boolean;
};

export const GridCol = styled.div`
  flex: 1;
`;

export const BackButton = styled(NavButton)`
  padding-right: 1rem;
  width: auto;
  margin-bottom: 2rem;

  span.first-icon {
    min-width: 2rem;
  }
`;

export const OnlineCatalogButton = styled(Button)<{ isInNavbar: boolean; showOnMobile?: boolean }>`
  ${({ theme, isInNavbar, showOnMobile }) => {
    return css`
      margin: 1rem 0;
      display: ${isInNavbar ? 'none' : 'block'};

      :active {
        color: ${theme.colors.black};
      }

      span {
        min-height: unset;
      }

      @media ${theme.device.medium} {
        margin: 0 1.5rem;
        padding: 0.5625rem 1.4375rem;
        display: ${!isInNavbar ? 'none' : 'block'};
        display: ${showOnMobile ? 'block' : 'none'};
        height: 2.75rem;

        ${media.noTouch`
          height: 2.5rem;
        `}
      }

      @media ${theme.device.large} {
        display: block;
        margin-right: 0;
        margin-left: 1.5rem;
      }
    `;
  }};
`;

export const HorizontalMarginWrapper = styled.div`
  ${({ theme }) => {
    return css`
      margin: 1rem 0;

      @media ${theme.device.medium} {
        margin: 0.5rem 0;
      }
    `;
  }}
`;

export const AnimationLayerContainer = styled.div<Animation>(
  ({ theme, animationPhase, withDelay }) => {
    const animationStyles = css`
      animation-name: ${getAnimation};
      animation-duration: var(--animation-duration);
      animation-delay: ${withDelay && animationPhase === 2 ? '0.2s' : '0s'};
      opacity: 0;
      margin: 0 2rem 0 -2rem;
      animation-fill-mode: forwards;
    `;

    return css`
      :not(.withDesktopAnimation) {
        ${animationStyles}
      }
      @media ${theme.device.large} {
        ${animationStyles}
        &.animating {
        }
        :not(.withDesktopAnimation) {
          animation-name: none;
          animation-duration: unset;
          opacity: 1;
          margin: unset;
          animation-fill-mode: none;
        }
      }
    `;
  },
);

export const AnimationLayer = styled.div<{
  animationType: 'fadeIn' | 'fadeOut';
  animationDelay?: number;
}>(({ animationType, animationDelay = 0 }) => {
  return css`
    animation-name: ${animationType === 'fadeIn' ? fadeInRTLNavAnimation : fadeOutLTRNavAnimation};
    animation-duration: var(--animation-duration);
    opacity: 0;
    animation-fill-mode: forwards;
    ${animationDelay && `animation-delay: ${animationDelay}s`};
  `;
});

export const AnimationWrapper = styled.div<{ animationType: 'fadeIn' | 'fadeOut' | undefined }>(
  ({ animationType, theme }) => {
    return css`
      display: grid;
      grid-template-rows: 0fr;
      transition-property: grid-template-rows, padding-top;
      transition-duration: var(--animation-duration);
      transition-delay: ${animationType === 'fadeOut' ? ' var(--animation-duration)' : '0'};
      grid-template-rows: ${animationType === 'fadeIn' ? '1fr' : '0fr'};

      position: fixed;
      margin-top: 4.25rem;
      padding-top: ${animationType === 'fadeIn' ? '1.5rem' : 0};
      top: 0;
      left: 0;
      right: 0;
      background-color: ${theme.colors.white};
      z-index: 22;

      ${media.noTouch`
        margin-top: 4rem;
      `}

      > div {
        overflow: hidden;
        width: 100%;

        > div {
          height: 100vh;
        }
      }

      @media ${theme.device.medium} {
        padding-top: 1.5rem;
        width: 21rem;
        left: initial;
        transition-property: right, padding-top;
        grid-template-rows: 1fr;
        right: ${animationType === 'fadeIn' ? '0' : '-21rem'};
      }
    `;
  },
);

const fadeInRTLNavAnimation = keyframes`
  0% { opacity: 0 ; margin: 0 -2rem 0 2rem; }
  100% { opacity: 1; margin: 0; }
`;

const fadeInLTRNavAnimation = keyframes`
  0% { opacity: 0 ; margin: 0 2rem 0 -2rem; }
  100% { opacity: 1; margin: 0; }
`;

const fadeOutLTRNavAnimation = keyframes`
  0% { opacity: 1; margin: 0; }
  100% { opacity: 0 ; margin: 0 -2rem 0 2rem; }
`;

const fadeOutRTLNavAnimation = keyframes`
  0% { opacity: 1; margin: 0; }
  100% { opacity: 0 ; margin: 0 2rem 0 -2rem; }
`;

function getAnimation({ animationPhase, animationType }: Animation) {
  if (animationType === undefined) {
    return 'none';
  }

  if (animationType === 'deeper') {
    if (animationPhase === 1) {
      return fadeOutRTLNavAnimation;
    }
    return fadeInRTLNavAnimation;
  }
  if (animationPhase === 1) {
    return fadeOutLTRNavAnimation;
  }
  return fadeInLTRNavAnimation;
}

export const CSSVariables = styled.div<{ animationDuration: number }>(({ animationDuration }) => {
  return css`
    --animation-duration: ${animationDuration}ms;
    --meta-nav-animation-duration: 200ms;
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);

    --flyout-height: calc(100% + (var(--safe-area-inset-top) + var(--safe-area-inset-bottom)));
    --flyout-margin-top: 4.25rem;

    ${media.noTouch`
        --flyout-height:  calc(100%  + (var(--safe-area-inset-top) + var(--safe-area-inset-bottom)));
        --flyout-margin-top: 4rem;
      `}
  `;
});
