import produce from 'immer';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import { Reducer } from 'redux';

import { PageAction } from './actions';

type PageState = AppState['page'];

/**
 * @param {Object} state – current state
 * @param {Object} action – action
 *
 * @returns {Object} new state
 */
const reducer: Reducer<PageState, PageAction> = produce((draft: PageState, action: PageAction) => {
  switch (action.type) {
    case 'page/SET_PAGE':
      draft.page = action.page;
      break;

    case 'page/UPDATE_IMAGE':
      {
        const areasForUpdateImg = draft.page.contentAreas ?? {};
        for (let area in areasForUpdateImg) {
          areasForUpdateImg[area].forEach((element, i) => {
            if (!isEqual(element, action.contentAreas[area][i])) {
              areasForUpdateImg[area][i] = action.contentAreas[area][i];
            }
          });
        }
      }
      break;

    case 'page/DELETE_SECTION_ON_PAGE':
      {
        const areasForDeleteSection = draft.page.contentAreas ?? {};
        for (let area in areasForDeleteSection) {
          areasForDeleteSection[area] = areasForDeleteSection[area].filter(
            (i) => i.previewId !== action.previewId,
          );
        }
      }
      break;

    case 'page/UPDATE_SECTION_ON_PAGE':
      {
        const areasForUpdateSection = draft.page?.contentAreas ?? {};
        for (let area in areasForUpdateSection) {
          areasForUpdateSection[area].forEach((element, i) => {
            if (element.previewId === action.previewId) {
              areasForUpdateSection[area][i] = action.content;
            }
          });
        }
      }
      break;

    case 'page/MOVE_SECTION_ON_PAGE':
      {
        const areasForMoveSection = draft.page.contentAreas ?? {};

        Object.keys(areasForMoveSection).forEach((areaName) => {
          let positionToInsert;
          let currentPosition;
          const sections = areasForMoveSection[areaName];
          sections.forEach((section, index) => {
            if (section.previewId === action.sectionPreviewId) {
              currentPosition = index;
            }
          });

          if (action.direction === 'DOWN') {
            positionToInsert = currentPosition + action.step;
          } else {
            positionToInsert = currentPosition - action.step;
          }
          sections.splice(positionToInsert, 0, sections.splice(currentPosition, 1)[0]);
        });
      }
      break;

    case 'page/ADD_SECTION_TO_PAGE':
      {
        const areasForAddSection = draft.page.contentAreas ?? {};

        const key = Object.hasOwnProperty.call(
          draft.page.contentAreas,
          action.newSection.context.body,
        )
          ? action.newSection.context.body
          : 'landingpage_content';
        const content = areasForAddSection[key];
        const ref = isEmpty(content) ? [] : content;

        areasForAddSection[key] = ref.concat({
          ...action.newSection.content,
        });
      }
      break;
    case 'page/UPDATE_PAGE': {
      draft.page.lastFetched = action.lastFetched;
      const pageType = action.page.type;
      Object.keys(action.page).forEach((pageKey) => {
        // on content change showroom locator data will not be included in the
        // content json provided by tpp, so we don't need to override them
        if (!action.isNewPage && pageType === 'showroom_locator' && pageKey === 'data') {
          return;
        }
        draft.page[pageKey] = action.page[pageKey];
      });
      break;
    }
  }
}, {});

export default reducer;
