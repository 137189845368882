import styled, { css } from 'styled-components';
import Link from 'next/link';
import { media } from 'mm/utils/no-touch-device';
import { clampLine } from 'mm/utils/clamp-line';

// utils

type IconPosition = 'left' | 'right' | 'both' | 'none';

const baseStyles = ({ theme, hasPictogram, pointerEvents }) => css`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 2.75rem;
  display: flex;
  align-items: ${hasPictogram ? 'center' : 'flex-start'};
  flex-direction: column;
  font-size: ${theme.fontSizes[2]}px;
  line-height: ${theme.lineHeights[1]}px;
  font-weight: ${theme.fontWeights.medium};
  border: 1px solid transparent;
  outline-offset: 0;
  transition: none;
  padding: ${hasPictogram ? 'calc(1rem - 1px) 0.5rem' : 'calc(0.625rem - 1px) 0.5rem'};
  hyphens: manual;
  pointer-events: ${pointerEvents};
`;

const imgStyles = () => css`
  img {
    position: static;
    height: 4rem;
    width: 4rem;
    margin: 0;
  }
`;

const spanStyles = () => css`
  span {
    text-align: center;
    ${clampLine(3)}
  }
`;

export const StyledLink = styled(Link)<{
  selected?: boolean;
  isOption?: boolean;
  $iconPosition: IconPosition;
  $hasPictogram?: boolean;
  thirdLevel?: boolean;
  $notClickable?: boolean;
}>`
  ${({
    theme,
    selected,
    $iconPosition,
    $hasPictogram: hasPictogram,
    thirdLevel,
    $notClickable: notClickable,
  }) => {
    const pointerEvents = notClickable ? 'none' : 'auto';

    return css`
      ${baseStyles({
        theme,
        hasPictogram,
        pointerEvents,
      })}

      > .picto {
        height: 4rem;
        width: 4rem;
        margin: 0 0 0.5rem 0;
      }

      && {
        color: ${selected ? theme.colors.primaryLight : theme.colors.black};
        border-radius: 0px;
        border-bottom-color: ${selected ? theme.colors.primaryLight : 'transparent'};
      }

      ${media.noTouch`
        min-height: 2.5rem;
      `}

      &:before {
        content: none;
      }

      &&:visited:focus {
        text-decoration: none;
      }

      &:focus,
      &:link {
        text-decoration: none;
      }

      ${media.hover`
        text-decoration: none;
        background-color: rgba(${theme.colors.transparentBlack}, 0.08);
      `}

      &&:focus-visible {
        border: 1px solid ${theme.colors.primaryLight};
        outline: 1px solid ${theme.colors.primaryLight};
        text-decoration: none;
      }

      &:active {
        ${({ theme }) => theme.global.disableTapHighlight}
        text-decoration: none;
        background-color: ${theme.colors.cl15};
        color: ${theme.colors.white};
      }

      ${imgStyles()}
      ${spanStyles}
    `;
  }}
`;

export const NavItemContainer = styled.li`
  ${() => {
    return css`
      width: 100%;
      padding: 0;
      list-style: none;
      flex: 0 0 calc(100% / 5);
    `;
  }}
`;
