import { useContext } from 'react';
import { useSelector } from 'react-redux';

// components
import { BUTTON_TYPES, BUTTON_COLORS, Button } from 'components/Button/Button';
import { Translation } from 'components/Translation/Translation';
import { NavigationContext, UPDATE_LANGUAGESHOWN } from 'components/Navigation/NavigationContext';

// styles
import styles from './language-switch-toggle.module.scss';

// selectors
import { alternativePageLanguagesSelector } from 'utils/selectors/pageSelectors';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { isEmpty } from 'utils/is-empty';

export default function LanguageSwitchToggle() {
  const { dispatch } = useContext(NavigationContext);
  const activeLanguage = useCurrentLanguage();
  const languages = useSelector(alternativePageLanguagesSelector);

  if (isEmpty(languages) || languages.length < 2) return null;

  const language = languages.find((el) => el.lang === activeLanguage);
  const showLanguage = () => {
    dispatch({ type: UPDATE_LANGUAGESHOWN, state: true });
  };

  return (
    <div className={styles.languageToggleWrapper}>
      <div className={styles.languageToggleInner}>
        <span className={styles.languageToggleDesc}>
          <Translation id="web20_language_selector_headline" />:
        </span>
        <Button
          type={BUTTON_TYPES.PLAIN}
          color={BUTTON_COLORS.PRIMARY}
          onClick={showLanguage}
          noTracking
        >
          {language?.lang?.substring(0, 2)}
        </Button>
      </div>
    </div>
  );
}
