import styled, { css } from 'styled-components';
import Link from 'next/link';

// utils
import { media } from 'mm/utils/no-touch-device';
import { memoizedFilterForSvgColor } from 'mm/utils/hex-to-filter-converter';

type IconPosition = 'left' | 'right' | 'both' | 'none';

const baseStyles = ({
  theme,
  $iconPosition,
  hasPictogram,
  thirdLevel,
  pointerEvents,
  isOverviewItem,
}) => css`
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 2.75rem;
  display: flex;
  align-items: center;
  padding: ${isOverviewItem
    ? '0.375rem 2.75rem 0.375rem 0.5rem'
    : '0.75rem 2.75rem 0.75rem 0.5rem'};
  font-size: ${theme.fontSizes[2]}px;
  line-height: ${theme.lineHeights[1]}px;
  font-weight: ${theme.fontWeights.medium};
  transition: none;
  ${$iconPosition === 'left' ? 'padding-left: 2.75rem' : ''};
  ${$iconPosition === 'both' ? 'padding-left: 2.75rem;' : ''};
  ${hasPictogram ? 'padding: 0.875rem 0.5rem 0.875rem 3rem;' : ''};
  ${thirdLevel ? 'padding-right: 0.5rem;' : ''};
  hyphens: manual;
  pointer-events: ${pointerEvents};
`;

const imgStyles = ({ selected, filterSvgColorPrimaryLight, thirdLevel }) => css`
  img {
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    margin: 0.75rem;
    left: 0;
    top: 0;
    ${selected && !thirdLevel && filterSvgColorPrimaryLight}

    .pictogram & {
      top: unset;
      height: 2rem;
      width: 2rem;
      margin: 0;
    }
  }
`;

const spanStyles = ({ isOption, selected }) => css`
  span {
    min-height: unset;

    &.first-icon,
    &.last-icon {
      position: absolute;
      display: flex;
      justify-content: center;
      padding: 0.75rem;

      svg {
        width: 1.25rem;
        visibility: ${(function () {
          if (isOption) {
            if (selected) {
              return 'visible';
            }
            return 'hidden';
          }

          return 'visible';
        })()};
        fill: currentColor;
      }
    }

    &.first-icon {
      left: 0;
      top: 0;
    }

    &.last-icon {
      right: 0;
    }
  }
`;

export const StyledLink = styled(Link)<{
  selected?: boolean;
  $isOption?: boolean;
  $iconPosition: IconPosition;
  $hasPictogram?: boolean;
  $firstLevel?: boolean;
  $thirdLevel?: boolean;
  $isOverviewItem?: boolean;
  $notClickable?: boolean;
}>`
  ${({
    theme,
    selected,
    $iconPosition,
    $isOption: isOption,
    $hasPictogram: hasPictogram,
    $thirdLevel: thirdLevel,
    $isOverviewItem: isOverviewItem,
    $notClickable: notClickable,
  }) => {
    const filterSvgColorWhite = memoizedFilterForSvgColor(theme.colors.white);
    const filterSvgColorPrimaryLight = memoizedFilterForSvgColor(theme.colors.primaryLight);
    const pointerEvents = notClickable ? 'none' : 'auto';

    return css`
      ${baseStyles({
        theme,
        $iconPosition,
        hasPictogram,
        thirdLevel,
        pointerEvents,
        isOverviewItem,
      })}

      svg.overview-icon {
        stroke: ${selected ? theme.colors.primaryLight : theme.colors.black};
      }

      && {
        color: ${selected ? theme.colors.primaryLight : theme.colors.black};
      }

      /* min-height: 2.4rem; */
      /* padding: 0.57rem 2.5rem 0.57rem 0.5rem; */

      &:before {
        content: none;
      }

      &&:visited:focus {
        text-decoration: none;
      }

      &:focus,
      &:link {
        text-decoration: none;
      }

      ${media.hover`
        text-decoration: none;
        background-color: rgba(${theme.colors.transparentBlack}, 0.08);
      `}

      &:active {
        ${({ theme }) => theme.global.disableTapHighlight}
        text-decoration: none;
        background-color: ${theme.colors.cl15};
        color: ${theme.colors.white};

        svg.overview-icon {
          stroke: ${theme.colors.white};
        }

        > img {
          ${!thirdLevel && filterSvgColorWhite}
        }
      }

      & .pictogram {
        position: absolute;
        left: 0;
        height: 2rem;
        width: 2rem;
        margin: 0 0.5rem;

        @media ${({ theme }) => theme.device.large} {
          position: unset;
          left: unset;
        }
      }

      :active {
        && img {
          ${filterSvgColorWhite}
        }
      }

      ${imgStyles({ selected, filterSvgColorPrimaryLight, thirdLevel })}
      ${spanStyles({ isOption, selected })}
    `;
  }}
`;

export const OverviewArrowWrapper = styled.div`
  padding: 8px 4px 8px 1px;
  display: flex;
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
`;
