// packages
import { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

// styles
import styles from './main-navigation-mobile-toggle.module.scss';
import {
  NavigationContext,
  NAVIGATION_OPEN,
  NAVIGATION_CLOSED,
  UPDATE_NAVIGATION_FLYOUT,
} from '../../../NavigationContext';

// selectors
import { translationByKeySelector } from '../../../../../utils/selectors/translationSelectors';

/**
 * Header Menu Toggle
 */
function MainNavigationMobileToggle() {
  const {
    state: { flyoutState },
    dispatch,
  } = useContext(NavigationContext);

  const mainNavigationMobileToggleLabel: string = useSelector((appState: AppState) =>
    translationByKeySelector(appState, 'web20_main_navigation_mobile_toggle_label'),
  );

  const toggle = () => {
    if (flyoutState === NAVIGATION_OPEN) {
      document.body.classList.remove('mobilenav-open');
      dispatch({ type: UPDATE_NAVIGATION_FLYOUT, state: NAVIGATION_CLOSED });
    } else {
      document.body.classList.add('mobilenav-open');
      dispatch({ type: UPDATE_NAVIGATION_FLYOUT, state: NAVIGATION_OPEN });
    }
  };

  useEffect(
    () => () => {
      document.body.classList.remove('mobilenav-open');
      dispatch({ type: UPDATE_NAVIGATION_FLYOUT, state: NAVIGATION_CLOSED });
    },
    [],
  );

  return (
    <button
      type="button"
      className={styles.mainNavigationMobileToggle}
      onClick={toggle}
      aria-label={mainNavigationMobileToggleLabel}
    >
      <div className={styles.mainNavigationMobileToggleInner}>
        <span />
      </div>
    </button>
  );
}

export default MainNavigationMobileToggle;
