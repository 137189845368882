import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { Button } from '@geberit/gdds';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';

// styles
import {
  SearchBar,
  StyledSearch,
  SuggestionOption,
  Suggestions,
  StyledContent,
  SearchIcon,
} from './search.styles';

// utils
import { globalsSearchSelector } from 'utils/selectors/globalsSelectors';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { useSearchSuggestions } from './use-search-suggestions';
import { useSearchOpen } from '../mega-menu-provider';
import { useFocusVisible } from 'utils/focus-visible';
import { useIsDesktop } from 'components/App/SizeProvider';

const MAX_SUGGESTIONS = 5;

export function Search({ className }: Readonly<{ className: string }>) {
  const [value, setValue] = useState('');
  const [keyPressed, setKeyPressed] = useState(false);
  const [searchBarDimensions, setSearchBarDimensions] = useState<{
    width: number;
    top: number;
    left: number;
    height: number;
  }>();
  const suggestions = useSearchSuggestions(value);
  const searchConfig = useSelector(globalsSearchSelector);
  const searchPlaceholder = useTranslationByKey('web20_search_placeholder');
  const { isSearchOpen, setIsSearchOpen } = useSearchOpen();
  const { focusVisible, ...focusVisibleProps } = useFocusVisible();
  const router = useRouter();
  const isDesktop = useIsDesktop({ gdds: true });
  const styledSearchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const getPhraseChunks = useCallback(
    (phrase: string) => {
      return buildPhraseChunks(phrase, value);
    },
    [value],
  );

  useEffect(() => {
    setValue('');
  }, [isSearchOpen]);

  useEffect(() => {
    if (focusVisible) {
      setKeyPressed(false);
    }
  }, [focusVisible]);

  useEffect(() => {
    if (!isDesktop) return;

    window.addEventListener('resize', searchBarDimensionsEventHandler);
    window.addEventListener('scroll', searchBarDimensionsEventHandler);
    searchBarDimensionsEventHandler();

    return () => {
      window.removeEventListener('resize', searchBarDimensionsEventHandler);
      window.removeEventListener('scroll', searchBarDimensionsEventHandler);
    };
  }, [isDesktop]);

  function searchBarDimensionsEventHandler() {
    setSearchBarDimensions(inputRef.current?.getBoundingClientRect());
  }

  const onKeyDownHandler = (e: KeyboardEvent<HTMLDivElement>) => {
    const list = styledSearchRef?.current?.querySelector('ul');
    const firstAnchorElement = list?.firstChild as HTMLLIElement;
    const lastAnchorElement = list?.lastChild as HTMLLIElement;
    const hoveredElement = list?.querySelector('li[data-hovered="true"]');

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (!hoveredElement) {
        setHovered(firstAnchorElement);
      } else {
        const nextElement = hoveredElement?.nextSibling as HTMLLIElement;
        if (nextElement) {
          setHovered(nextElement);
        } else {
          setHovered(firstAnchorElement);
        }
      }
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      const previousElement = hoveredElement?.previousSibling as HTMLLIElement;
      if (previousElement) {
        setHovered(previousElement);
      } else {
        setHovered(lastAnchorElement);
      }
    }

    if (e.key === 'Home') {
      e.preventDefault();
      setHovered(firstAnchorElement);
    }

    if (e.key === 'End') {
      e.preventDefault();
      setHovered(lastAnchorElement);
    }
  };

  return (
    <StyledSearch ref={styledSearchRef} onKeyDown={onKeyDownHandler} show={isSearchOpen}>
      <SearchBar
        className={className}
        focusVisible={focusVisible && !keyPressed}
        {...focusVisibleProps}
        ref={inputRef}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder={searchPlaceholder}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            let query = value;
            const hoveredElement = styledSearchRef.current?.querySelector(
              'ul > li[data-hovered="true"]',
            );
            if (hoveredElement) {
              query = (hoveredElement.firstChild as HTMLAnchorElement).text;
            }
            router.push(`${searchConfig?.searchResultPageUrl}?q=${query}`);
            setIsSearchOpen(false);
          }
          setKeyPressed(true);
        }}
        autoFocus
      />
      {value.length > 0 ? (
        <Button
          isIcon
          symbol="close"
          stylingType="icon"
          onClick={() => {
            setValue('');
            inputRef.current?.focus();
          }}
        />
      ) : (
        <SearchIcon symbol="search" />
      )}

      {isSearchOpen && suggestions.length > 0 && (
        <Suggestions
          left={searchBarDimensions?.left}
          top={(searchBarDimensions?.top ?? 0) + (searchBarDimensions?.height ?? 0)}
          width={searchBarDimensions?.width}
        >
          {suggestions.slice(0, MAX_SUGGESTIONS).map((item) => {
            const [start, input, rest] = getPhraseChunks(item);
            return (
              <SuggestionOption
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    router.push(`${searchConfig?.searchResultPageUrl}?q=${item}`);
                  }
                }}
                onMouseOver={(e) => {
                  setHovered(e.currentTarget);
                }}
                onMouseLeave={(e) => {
                  resetHoverState();
                }}
                key={item}
              >
                <Link
                  onClick={() => {
                    setIsSearchOpen(false);
                  }}
                  href={`${searchConfig?.searchResultPageUrl}?q=${item}`}
                >
                  <StyledContent>
                    {start && <span>{start}</span>}
                    {input && <strong>{input}</strong>}
                    {rest && <span>{rest}</span>}
                  </StyledContent>
                </Link>
              </SuggestionOption>
            );
          })}
        </Suggestions>
      )}
    </StyledSearch>
  );

  function resetHoverState() {
    const list = styledSearchRef?.current?.querySelectorAll('ul > li');
    list?.forEach((e) => {
      e.setAttribute('data-hovered', 'false');
    });
  }

  function setHovered(anchorElement: HTMLLIElement) {
    resetHoverState();
    anchorElement?.setAttribute('data-hovered', 'true');
  }
}

function buildPhraseChunks(phrase: string, term = '') {
  if (typeof phrase !== 'string') {
    return [undefined, undefined, undefined];
  }

  if (!includesCaseInSensitive(phrase, term)) {
    return ['', '', phrase];
  }

  const [start, ...rest] = phrase.toLowerCase().split(term.toLowerCase());
  const end = rest.join(term);

  // return parts of the original string to ensure that case is preserved
  return [
    phrase.slice(0, start.length).trimStart(),
    phrase.slice(start.length, start.length + term.length),
    end ? phrase.slice(-end.length) : '',
  ];
}

function includesCaseInSensitive(phrase: string, term: string) {
  return phrase.toLowerCase().includes(term.toLowerCase());
}
