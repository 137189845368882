import { usePathname } from 'next/navigation';

// components
import { NavItem } from '../common/nav-item';

// utils
import { useNavOpen } from '../mega-menu-provider';
import { useReactKeys } from '../language-switch/use-react-keys';
import { decodingContent } from 'utils/decodingContent';

type Item = {
  url: string;
  label: string;
  ariaLabel?: string;
};

export type LocatorFlyoutProps = {
  items: Item[];
  onChange?: () => void;
};

export function LocatorSwitch({ items, onChange }: Readonly<LocatorFlyoutProps>) {
  const { setIsNavOpen } = useNavOpen();
  const itemsWithChildren = useReactKeys(items, ['ariaLabel', 'label', 'url']);
  const path = usePathname();
  const selectedIndex = findSelectedItemIndex(items, path);

  return (
    <>
      {itemsWithChildren?.map((item, index) => {
        return (
          <NavItem
            isOption
            selected={index === selectedIndex}
            href={item.url || ''}
            aria-label={decodingContent(item?.ariaLabel)}
            key={item.key}
            leftIconName="checkmark"
            tabIndex={-1}
            onClick={() => {
              onChange?.();
              setIsNavOpen(false);
            }}
          >
            {decodingContent(item.label)}
          </NavItem>
        );
      })}
    </>
  );

  /**
   * find selected item index
   * @example
   * // returns 1
   * findSelectedItemIndex([ {url: '/a/b2'}, {url: '/a/b'}], "a/b/c");
   * @example
   * // returns 0
   * findSelectedItemIndex([ {url: '/a/b2'}, {url: '/a/b'}], "/a/b2/c");
   * @example
   * // returns 1
   * findSelectedItemIndex([ {url: '/a/b'}, {url: '/a/b2'}], "/a/b2/c");
   * @returns {Number} Returns selected item index.
   */
  function findSelectedItemIndex(items: Item[], path: string): number | undefined {
    let itemIndex: number | undefined = undefined;
    items
      .slice()
      .map((item, itemIndex) => ({ ...item, itemIndex }))
      .sort((a, b) => a.url.length - b.url.length)
      .forEach((item) => {
        if (removeLeadingAndTrailingSlash(path).includes(removeLeadingAndTrailingSlash(item.url))) {
          itemIndex = item.itemIndex;
        }
      });

    return itemIndex;
  }

  function removeLeadingAndTrailingSlash(url: string): string {
    return url?.replace(/(^\/)|(\/$)/g, '');
  }
}
