import { useRef, useEffect, useContext, useState } from 'react';

// styles
import styles from './off-canvas-wrapper.module.scss';

// components
import MetaNavigation from 'components/Navigation/MetaNavigation/MetaNavigation';
import LanguageSelector from 'components/Navigation/LanguageSelector/LanguageSelector';
import LanguageSwitchToggle from 'components/Navigation/MainNavigation/Mobile/Toggle/LanguageSwitchToggle';
import MenuLayer from 'components/Navigation/MainNavigation/Mobile/Menu/menu-layer';
import { NavigationContext } from 'components/Navigation/NavigationContext';

// utils
import { useGdds } from 'utils/hooks/use-gdds';
import { useNord } from 'utils/hooks/use-nord';

interface OffCanvasWrapperProps {
  items: any[];
  handleClick: any;
  pageType?: string | null;
  translationToOverview?: string | null;
  isCampus?: boolean;
}

function OffCanvasWrapper({
  items,
  handleClick,
  translationToOverview = null,
  isCampus = false,
  pageType = null,
  ...props
}: Readonly<OffCanvasWrapperProps>) {
  const [metaState, setMetaState] = useState(false);
  const [menuState, setMenuState] = useState(false);
  const {
    state: { clickPath, flyoutState, languageShown },
  } = useContext(NavigationContext);
  const wrapperRef = useRef(null);
  const langRef = useRef(null);
  const navRef = useRef(null);
  const isGdds = useGdds();
  const isNordics = useNord();
  const isGroup = isGdds && !isNordics;

  /**
   * transition events for wrapper shown or hidden
   * e.g. flyout open or closed
   */
  const onTransitionEnd = (e) => {
    if (e.target === wrapperRef.current) {
      if (document.querySelector('.mobilenav-open')) {
        e.target.style.transitionDelay = '0.4s';
        setMetaState(true);
        setMenuState(true);
      } else {
        e.target.style.transitionDelay = '0s';
      }
    }
    if (e.target === navRef.current) {
      if (document.querySelector('.language--shown')) {
        navRef.current.style.transitionDelay = '0.2s';
      } else if (document.querySelector('.language--hidden')) {
        navRef.current.style.transitionDelay = '0s';
      }
    }
  };

  /**
   * we need to make sure the slide out animation
   * gets a delay
   */
  const onTransitionStart = (e) => {
    if (e.target === wrapperRef.current) {
      setMetaState(false);
      setMenuState(false);
    }

    if (e.target === langRef.current && languageShown === true) {
      setMenuState(false);
    } else if (e.target === langRef.current && languageShown === false) {
      setMenuState(true);
      navRef.current.style.marginBottom = 0;
    }
  };

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('transitionend', onTransitionEnd);
      wrapperRef.current.addEventListener('transitioncancel', onTransitionEnd);
      wrapperRef.current.addEventListener('transitionrun', onTransitionStart);
    }

    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('transitionend', onTransitionEnd);
        wrapperRef.current.removeEventListener('transitioncancel', onTransitionEnd);
        wrapperRef.current.removeEventListener('transitionrun', onTransitionStart);
      }
    };
  }, [languageShown, flyoutState]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      wrapperRef.current?.scrollTo(0, 0);
    }, 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [clickPath, languageShown]);

  return (
    <div className={styles.Wrapper} ref={wrapperRef}>
      <div className={styles.Main}>
        <div className={menuState ? styles.MenuShown : styles.MenuHidden} ref={navRef}>
          <MenuLayer
            translationToOverview={translationToOverview}
            items={items}
            isCampus={isCampus}
            handleClick={handleClick}
          />
        </div>
        <div className={styles.Lang}>
          <div className={languageShown ? styles.LangShown : styles.LangHidden} ref={langRef}>
            <LanguageSelector />
          </div>
        </div>
      </div>
      <div className={styles.Meta}>
        <div className={metaState ? styles.MetaShown : styles.MetaHidden}>
          <div className={isGroup && styles.isGroup}>
            <MetaNavigation isCampus={isCampus} {...props} />
            {pageType !== 'error_page' && pageType !== 'search_results' && <LanguageSwitchToggle />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OffCanvasWrapper;
