import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/vsts/work/1/s/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/src/app/registry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationContextProvider"] */ "/home/vsts/work/1/s/src/components/Navigation/NavigationContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/src/features/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useIsoLang"] */ "/home/vsts/work/1/s/src/scenes/MetaData.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Scripts"] */ "/home/vsts/work/1/s/src/scenes/Scripts.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/src/styles/global.scss");
