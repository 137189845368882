import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import Link from 'next/link';

// styles
import styles from './menu-layer.module.scss';

// components
import { BUTTON_TYPES, Button } from 'components/Button/Button';
import { NAVIGATION_RESTRICTIONS } from 'components/Navigation/constants';

// utils
import { isEmpty } from 'utils/is-empty';
import { useTracking } from 'utils/hooks/useTracking';
import { navigationTrackingAction } from 'components/Navigation/trackingActions';
import { campusReferenceUrlSelector } from 'utils/selectors/globalsSelectors';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { classNameBuilder } from 'utils/classNameBuilder';

interface MenuLayerListEntryProps {
  item: any;
  handleClick: any;
  index: number;
}

function MenuLayerListEntry({ item, handleClick, index }: Readonly<MenuLayerListEntryProps>) {
  const hasChildren = item.children && item.children.length > 0;
  const [isActive, setIsActive] = useState<boolean>(false);
  const track = useTracking();
  const campusReference = useSelector(campusReferenceUrlSelector);
  const [isExternal, setIsExternal] = useState(false);
  const { asPath: pathname } = useRouter();
  const translate = useTranslationFunction();
  const catalogTranslation = translate('campus_nav_catalog') || 'Catalog';

  useEffect(() => {
    if (campusReference.length > 1 && item.url?.startsWith(campusReference)) {
      setIsActive(item.url === pathname || item.label === catalogTranslation);
    } else {
      setIsActive(Boolean(item.url && pathname === item.url));
    }
    if (item.url?.indexOf('http://') === 0 || item.url?.indexOf('https://') === 0)
      setIsExternal(true);
  }, [campusReference, catalogTranslation, item.label, item.url, pathname]);

  const handleTrackedClick = () => {
    track.trackEvent(navigationTrackingAction(item.label));
    handleClick(item, index, true);
  };

  if (hasChildren && item.url !== campusReference) {
    return (
      <li
        className={classNameBuilder(
          styles.MenuLayerListEntry,
          isActive && styles.MenuLayerListEntryActive,
        )}
      >
        <Button
          type={BUTTON_TYPES.PLAIN}
          className="next"
          onClick={() => handleClick(item, index)}
          noTracking
        >
          {item.label?.substring(0, NAVIGATION_RESTRICTIONS[index + 1])}
        </Button>
      </li>
    );
  }

  if (isEmpty(item.url)) {
    return (
      <li className={styles.MenuLayerListEntry}>
        <span>{item.label?.substring(0, NAVIGATION_RESTRICTIONS[index + 1])}</span>
      </li>
    );
  }

  return (
    <li
      className={classNameBuilder(
        styles.MenuLayerListEntry,
        isActive && styles.MenuLayerListEntryActive,
      )}
    >
      {isExternal ? (
        <a href={item.url} onClick={handleTrackedClick}>
          {item.label?.substr(0, NAVIGATION_RESTRICTIONS[index + 1])}
        </a>
      ) : (
        <Link href={item.url} onClick={handleTrackedClick}>
          {item.label?.substr(0, NAVIGATION_RESTRICTIONS[index + 1])}
        </Link>
      )}
    </li>
  );
}

export default MenuLayerListEntry;
