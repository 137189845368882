import produce from 'immer';
import { Reducer } from 'redux';

import {
  ADD_PRODUCT,
  DECREASE_PRODUCT,
  DELETE_PRODUCT,
  LOAD_LIST,
  BASKET_FAILURE,
  SET_PRODUCT,
  ARTICLE_COUNT_SUCCESS,
  BASKET_FINISHLOADING,
  BASKET_LOADING,
} from './actions';
import { BasketAction } from './actions.types';
import { BasketState } from './reducer.types';

const inital = {
  items: undefined,
  count: 0,
  error: null,
  isLoading: false,
};

const basketReducer: Reducer<BasketState, BasketAction> = produce(
  (draft: BasketState, action: BasketAction) => {
    switch (action.type) {
      case ADD_PRODUCT: {
        const element = draft.items.find(
          (entry) =>
            entry.key === action.id ||
            entry.key === action.product ||
            entry.key === action.product.key ||
            (entry.productId === action.product.productId &&
              entry.variantId === action.product.variantId),
        );
        if (element) {
          element.quantity += action.product.quantity ? action.product.quantity : 1;
          draft.count += action.product.quantity ? action.product.quantity : 1;
        } else {
          draft.items.push(action.product);
          draft.count += action.product.quantity;
        }
        break;
      }

      case BASKET_LOADING: {
        draft.isLoading = true;
        break;
      }

      case BASKET_FINISHLOADING: {
        draft.isLoading = false;
        break;
      }

      case DECREASE_PRODUCT: {
        const element = draft.items.find((entry) => entry.key === action.id);
        if (element) {
          element.quantity -= 1;
          draft.count -= 1;
        }
        break;
      }

      case SET_PRODUCT: {
        const element = draft.items.find((entry) => entry.key === action.id);
        if (element) {
          const difference = action.count - element.quantity;
          element.quantity = action.count;
          draft.count += difference;
        }
        break;
      }

      case DELETE_PRODUCT: {
        const element = draft.items.find(
          (entry) =>
            entry.key === action.id ||
            entry.key === action.product ||
            (action.product && entry.key === action.product.key) ||
            (action.product &&
              entry.productId === action.product.productId &&
              entry.variantId === action.product.variantId),
        );
        if (element) {
          draft.count -= element.quantity;
          draft.items = draft.items.filter((item) => item.key !== action.id);
        }
        break;
      }

      case LOAD_LIST: {
        draft.items = [...action.items];
        break;
      }

      case ARTICLE_COUNT_SUCCESS:
        draft.count = action.count;
        break;

      case BASKET_FAILURE:
        draft.error = action.error;
        break;

      default:
    }
  },
  inital,
);

export default basketReducer;
