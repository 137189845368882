import { HTMLProps, PropsWithChildren } from 'react';
import { Icon } from '@geberit/gdds';

// styles
import { StyledButton } from './nav-button.styles';

export function NavButton({
  selected,
  leftIconName,
  rightIconName,
  children,
  isOption,
  paddingLeft,
  highlighted,
  as,
  ...anchorProps
}: PropsWithChildren<
  {
    isOption?: boolean;
    selected?: boolean;
    paddingLeft?: boolean;
    leftIconName?: string;
    rightIconName?: string;
    highlighted?: boolean;
    type?: 'submit' | 'reset' | 'button';
  } & HTMLProps<HTMLButtonElement>
>) {
  return (
    <StyledButton
      paddingLeft={paddingLeft}
      selected={selected}
      highlighted={highlighted}
      $iconPosition={getIconPosition()}
      {...anchorProps}
    >
      {leftIconName && <Icon className="first-icon" symbol={leftIconName} />}
      {children}
      {rightIconName && <Icon className="last-icon" symbol={rightIconName} />}
    </StyledButton>
  );

  function getIconPosition() {
    if (leftIconName && rightIconName) {
      return 'both';
    }

    if (leftIconName) {
      return 'left';
    }

    if (rightIconName) {
      return 'right';
    }

    return 'none';
  }
}
