import produce from 'immer';
import { Reducer } from 'redux';
import { NavigationAction } from './actions';

type NavigationState = AppState['navigation'];

const reducer: Reducer<NavigationState, NavigationAction> = produce(
  (draft: NavigationState, action: NavigationAction) => {
    if (action.type === 'FETCH_HEADER_LINKS_FULFILLED') {
      draft[action.language].meta = action.data;
    }
    if (action.type === 'ADD_NAVIGATION') {
      Object.entries(action.navigation).forEach(([key, value]) => {
        draft[key] = value;
      });
    }
  },
  {},
);

export default reducer;
