import { useEffect, useCallback, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { ResizeObserver as PolyfillResizeObserver } from '@juggle/resize-observer';
import { usePathname } from 'next/navigation';

// components
import MainNavigationView from './MainNavigationView';

// utils
import {
  navigationMainItemsSelector,
  navigationMainCampusItemsSelector,
} from 'utils/selectors/navigationSelectors';
import { homepageSelector } from 'utils/selectors/homepageListSelectors';
import { useCampusAuthGate } from 'utils/hooks/useCampus';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { useNord } from 'utils/hooks/use-nord';
import {
  UPDATE_NAVIGATION_FLYOUT,
  UPDATE_CLICKPATH,
  NAVIGATION_OPEN,
  NAVIGATION_CLOSED,
  NavigationContext,
  SET_OVERRIDEPATH,
} from '../NavigationContext';
import {
  PreviewContext,
  RESET_NAVIGATION_RESTRICTION,
  ADD_NAVIGATION_RESTRICTION,
} from '../../ContentCreator/PreviewContext';
import { NAVIGATION_RESTRICTIONS } from '../constants';
import { currentPageSelector } from 'utils/selectors/pageSelectors';

type MainNavigationProps = {
  isCampus?: boolean;
  isDesktop?: boolean;
  isSticky?: boolean;
};

function MainNavigation({ isCampus, isDesktop, isSticky }: Readonly<MainNavigationProps>) {
  const pathname = usePathname();
  const isPreview = useIsPreview();
  const { ciamToken } = useCampusAuthGate();
  const navigation = useSelector(
    !isCampus ? navigationMainItemsSelector : navigationMainCampusItemsSelector,
  );
  const page = useSelector(currentPageSelector);
  const homepage = useSelector(homepageSelector);
  const { state, dispatch } = useContext(NavigationContext) || {};
  const { dispatch: previewDispatch } = useContext(PreviewContext) || {};
  const [initialFirstLevel, setInitialFirstLevel] = useState<any[]>([]);
  const [activeFirstLevel, setActiveFirstLevel] = useState<any[]>([]);
  const navBorderRef = useRef<HTMLDivElement>();
  const wrapperRef = useRef<HTMLDivElement>();

  const initialActivePageTree = useRef<NavigationItem[]>([]);
  const isNord = useNord();

  /**
   * Close flyout
   */
  const closeFlyout = useCallback(() => {
    document.body.classList.remove('mobilenav-open');
    dispatch({ type: UPDATE_NAVIGATION_FLYOUT, state: NAVIGATION_CLOSED });
    if (page?.type === 'homepage') setActiveFirstLevel([]);
    else setActiveFirstLevel(initialFirstLevel);
  }, [dispatch, page?.type, initialFirstLevel]);

  const checkActiveDimensions = () => {
    const activeElement = document.querySelector('div[data-active-fl=true]');
    if (activeElement) {
      const walker = document.createTreeWalker(activeElement, NodeFilter.SHOW_ELEMENT);
      let leaf = walker.currentNode as HTMLElement;
      while (walker.firstChild()) {
        leaf = walker.currentNode as HTMLElement;
      }

      const navActive = [
        leaf,
        {
          offsetLeft: leaf.getBoundingClientRect().left,
          offsetWidth: leaf.getBoundingClientRect().width,
        },
      ];

      setActiveFirstLevel(navActive);
      setInitialFirstLevel(navActive);
    }
  };

  const navigationUnderline = useCallback(() => {
    setTimeout(() => {
      if (!isEmpty(activeFirstLevel) && navBorderRef.current) {
        const left =
          activeFirstLevel[0].offsetLeft ||
          activeFirstLevel[0]?.parentElement?.offsetLeft ||
          activeFirstLevel[1].offsetLeft;
        const width = activeFirstLevel[0].offsetWidth || activeFirstLevel[1].offsetWidth;

        navBorderRef.current.style.left = `${left}px`;
        navBorderRef.current.style.width = `${width}px`;
      } else if (navBorderRef.current) {
        navBorderRef.current.style.width = '0';
      }
    });
  }, [activeFirstLevel]);

  useEffect(() => {
    navigationUnderline();
  }, [activeFirstLevel, navigationUnderline]);

  useEffect(() => {
    // use native resize observer for better performance
    const ResizeObserver = window.ResizeObserver || PolyfillResizeObserver;
    const observer = new ResizeObserver(navigationUnderline);
    observer.observe(document.body);

    if (navBorderRef.current && wrapperRef.current && !navBorderRef.current.style.left) {
      navBorderRef.current.style.left = `${
        wrapperRef?.current?.querySelector('div:first-of-type')?.getBoundingClientRect().left
      }px`;
    }

    return () => {
      observer.disconnect();
    };
  }, [activeFirstLevel, initialFirstLevel, navigationUnderline]);

  useEffect(() => {
    let timeout: Timeout | undefined;
    if (page?.type) {
      document.body.classList.remove('mobilenav-open');
      dispatch({ type: UPDATE_NAVIGATION_FLYOUT, state: NAVIGATION_CLOSED });

      checkActiveDimensions();
      timeout = setTimeout(() => {
        checkActiveDimensions();
      }, 100);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [page, dispatch]);

  useEffect(() => {
    // on Nord: no additional '/home' element in breadcrumbs
    const minLength = isNord ? 1 : 2;

    if (
      page?.breadcrumbs?.length <= minLength &&
      !navigation?.some((el) => el.url === page.url) &&
      state &&
      state.flyoutState === NAVIGATION_CLOSED
    ) {
      setActiveFirstLevel([]);
      setInitialFirstLevel([]);
    }
  }, [navigation, page, state, isNord]);

  /**
   * when in preview: check navigation restrictions
   */
  useEffect(() => {
    if (isPreview && navigation && window.location !== window.parent.location) {
      previewDispatch({ type: RESET_NAVIGATION_RESTRICTION });

      const recursiveCheckRestrictions = (entry, level, path) => {
        entry.forEach((item) => {
          const restrictionCount = NAVIGATION_RESTRICTIONS[level] || 60;
          if (item.label && item.label.length > restrictionCount) {
            previewDispatch({
              type: ADD_NAVIGATION_RESTRICTION,
              entry: {
                level,
                label: item.label.substr(0, restrictionCount),
                toolong: item.label.substr(restrictionCount),
                url: item.url,
                path,
              },
            });
          }
          if (item.children && item.children.length > 0) {
            recursiveCheckRestrictions(item.children, level + 1, [
              ...path,
              { label: item.label, url: item.url },
            ]);
          }
        });
      };

      recursiveCheckRestrictions(navigation, 1, []);
    }

    if (navigation && pathname) {
      dispatch({ type: SET_OVERRIDEPATH, overridePath: [] });
      const findActivePathInLayer = (layerStack, layer, path, index) => {
        if (layer?.url === path && !layerStack.some((e) => e.url === path)) {
          layerStack.push({ ...layer, index });
          dispatch({ type: SET_OVERRIDEPATH, overridePath: layerStack });
        }
        if (layer?.children && layer.children.length > 0) {
          layer.children.forEach((layerChild) => {
            const branchStack = [...layerStack];
            if (!layer.isRoot) branchStack.push({ ...layer, index });
            findActivePathInLayer(branchStack, layerChild, path, index + 1);
          });
        }
      };
      findActivePathInLayer([], { isRoot: true, children: navigation }, pathname, 0);
    }
  }, [dispatch, isPreview, navigation, pathname, previewDispatch]);

  useEffect(() => {
    const pathParts = pathname.split('/').filter((p) => p.length);

    const getActiveArray = (items: NavigationItem[], level: number) => {
      const testPart = `/${[...pathParts].slice(0, level + 1).join('/')}/`;
      const activeItem = items.find((i) => {
        return i.url === testPart;
      });
      if (!activeItem) return;
      initialActivePageTree.current.push(activeItem);
      if (activeItem.children && level < pathParts.length) {
        getActiveArray(activeItem.children, level + 1);
      }
    };
    getActiveArray(navigation, 0);
    // only run once on component mount.
    // @see https://stackoverflow.com/a/58101280
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handle navigation click and update click path
   */
  const handleClick = useCallback(
    (currentItem: NavigationItem, i: number, isLink: boolean) => {
      if (!currentItem) {
        closeFlyout();
        return;
      }

      if (isLink) {
        return;
      }

      if (state && state.flyoutState === NAVIGATION_CLOSED) {
        dispatch({ type: UPDATE_NAVIGATION_FLYOUT, state: NAVIGATION_OPEN });
      }

      if (i === 0 && isDesktop) {
        const isOverride =
          state.overridePath &&
          state.overridePath.length > 0 &&
          state.overridePath[0].label === currentItem.label;

        const betterCurrentItems =
          currentItem.url === initialActivePageTree.current[0]?.url
            ? initialActivePageTree.current
            : [currentItem];

        const nextItems = isOverride
          ? (state.overridePath as NavigationItem[])
          : betterCurrentItems;

        dispatch({ type: UPDATE_CLICKPATH, clickPath: nextItems });
        return;
      }
      if (state.clickPath.length - 1 >= i) {
        const filteredPath = state.clickPath.filter((item, index) => index < i && item);
        const newClickPath = isDesktop ? [...filteredPath, currentItem] : filteredPath;

        dispatch({ type: UPDATE_CLICKPATH, clickPath: newClickPath });
        return;
      }
      dispatch({ type: UPDATE_CLICKPATH, clickPath: [...state.clickPath, currentItem] });
    },
    [state, closeFlyout, isDesktop, dispatch],
  );

  if (isEmpty(navigation)) return null;

  return (
    <MainNavigationView
      handleClick={handleClick}
      isDesktop={isDesktop}
      isCampus={isCampus}
      navBorderRef={navBorderRef}
      wrapperRef={wrapperRef}
      navigation={isCampus && !ciamToken ? [] : navigation}
      campusEmployee={!ciamToken}
      closeFlyout={closeFlyout}
      activeFirstLevel={activeFirstLevel}
      setActiveFirstLevel={setActiveFirstLevel}
      pageType={page?.type}
      page={page}
      pathname={pathname}
      isSticky={isSticky}
      homepage={homepage}
    />
  );
}

export default MainNavigation;
