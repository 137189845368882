import produce from 'immer';
import { Reducer } from 'redux';
import { TranslationAction } from './actions';

import { TranslationState } from './reducer.types';

const initialState = {};

/**
 * @param {Object} state – current state
 * @param {Object} action – action
 *
 * @returns {Object} new state
 */
const reducer: Reducer<TranslationState, TranslationAction> = produce(
  (draft: TranslationState, action: TranslationAction) => {
    if ('language' in action && draft[action.language] === undefined) {
      draft[action.language] = {};
    }

    switch (action.type) {
      case 'UPDATE_TRANSLATION':
        if (!action.translation) break;
        if (!draft[action.language].keys) {
          draft[action.language].keys = {};
        }
        draft[action.language].keys[action.translation.key] = action.translation.value;
        draft[action.language].keysWithPreviewId[action.translation.key] = action.translation;
        break;

      case 'TRANSLATIONS_REQUEST_PENDING':
        draft[action.language].isFetching = true;
        break;

      case 'ADD_TRANSLATION':
        Object.entries(action.translations).forEach(([key, value]) => {
          draft[key] = value;
        });
        break;

      case 'TRANSLATIONS_REQUEST_FULFILLED':
        const data = { ...action.data };
        Object.keys(data).forEach((key) => (data[key] = data[key].value || data[key]));
        draft[action.language].isFetching = false;
        draft[action.language].lastFetched = action.lastFetched;
        draft[action.language].error = false;
        draft[action.language].keys = data;
        draft[action.language].keysWithPreviewId = action.data;
        break;

      case 'TRANSLATIONS_REQUEST_REJECTED':
        draft[action.language].isFetching = false;
        draft[action.language].error = {
          status: action.error.status,
          statusText: action.error.statusText,
        };
        break;

      default:
    }
  },
  initialState,
);

export default reducer;
