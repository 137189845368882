import key from 'weak-key';
import { usePathname } from 'next/navigation';

// styles
import styles from './meta-navigation.module.scss';

// components
import { CmsLink } from 'components/Link/CmsLink';
import { EditWrapper } from 'components/ContentCreator/EditWrapper';

// utils
import { metaNavigationTrackingAction } from '../trackingActions';
import { useTracking } from 'utils/hooks/useTracking';
import { isEmpty } from 'utils/is-empty';

export default function MetaNavigationLinks({
  previewId,
  links,
}: Readonly<{ previewId: string; links: Link[] }>) {
  const track = useTracking();
  const pathname = usePathname();

  return (
    <EditWrapper previewId={previewId} editType="meta_navigation">
      <nav className={styles.headerLinks}>
        <ul>
          {!isEmpty(links) &&
            links.map((link) => {
              const isActive = pathname === link.target ? styles.active : '';

              return (
                <li key={key(link)} className={isActive}>
                  <CmsLink
                    link={{
                      target: link.target,
                      type: link.type,
                      text: link.text,
                      window: link.window,
                    }}
                    className={styles.link}
                    tracking={() => track.trackEvent(metaNavigationTrackingAction(link.text))}
                  />
                </li>
              );
            })}
        </ul>
      </nav>
    </EditWrapper>
  );
}
