import { useState, useEffect, memo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePathname } from 'next/navigation';

// components
import MetaNavigationView from './MetaNavigationView';

// selectors
import {
  webshopEnabledSelector,
  webshopBasketPageUrlSelector,
  ocbSelector,
  ciamEnabledSelector,
} from 'utils/selectors/globalsSelectors';
import { webshopMaintenanceEnabledSelector } from 'utils/selectors/maintenanceSelectors';
import { currentCatalogSelector } from 'utils/selectors/productCatalogSelectors';
import { navigationMetaItemsSelector } from 'utils/selectors/navigationSelectors';

// actions
import { loadArticleCounter } from 'components/ShoppingBasket/actions';
import { SessionContext } from 'components/Session/SessionContext';

function MetaNavigation({ isCampus }) {
  const pathname = usePathname();

  const {
    state: { session, ciam, sessionIsLoading },
  } = useContext(SessionContext);

  const [activeIcons, setActiveIcons] = useState([]);
  const dispatch = useDispatch();
  const ciamEnabled = useSelector(ciamEnabledSelector);
  const ocb = useSelector(ocbSelector);
  const webshopEnabled = useSelector(webshopEnabledSelector);
  const webshopBasketPageUrl = useSelector(webshopBasketPageUrlSelector);
  const currentCatalog = useSelector(currentCatalogSelector);
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);
  const metaNavigationItems = useSelector(navigationMetaItemsSelector);

  /**
   * Update active action icon list
   *
   * @param {String} name
   * @param {Bool} isRouteChange
   */
  const updateActiveIcon = (name, isRouteChange) => {
    if (activeIcons.includes(name) && !isRouteChange) {
      const newIcons = activeIcons.filter((icon) => icon !== name);
      setActiveIcons(newIcons);
    } else {
      setActiveIcons([...activeIcons, name]);
    }
  };

  useEffect(() => {
    if (pathname === webshopBasketPageUrl) {
      updateActiveIcon('shoppingcart', true);
    }
  }, [pathname, webshopBasketPageUrl]);

  useEffect(() => {
    if (currentCatalog && !sessionIsLoading && webshopEnabled && !webshopDowntime) {
      dispatch(loadArticleCounter(session, currentCatalog, ciam));
    }
  }, [currentCatalog, session, ciam, sessionIsLoading, webshopEnabled, webshopDowntime, dispatch]);

  return (
    <MetaNavigationView
      metaNavigationItems={metaNavigationItems}
      ciamEnabled={ciamEnabled}
      webshopEnabled={webshopEnabled && !webshopDowntime}
      ocb={ocb}
      isCampus={isCampus}
      activeIcons={activeIcons}
      webshopBasketPageUrl={webshopBasketPageUrl}
      updateActiveIcon={updateActiveIcon}
    />
  );
}

export default memo(MetaNavigation);
