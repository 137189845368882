import styled, { css } from 'styled-components';
import { Container } from '@geberit/gdds';

// styles
import { OnlineCatalogButton } from '../common/common.styles';
import { StyledButton } from '../common/nav-button.styles';
import { StyledLink } from '../switch/nav-item.styles';

// utils
import { media } from '../utils/no-touch-device';
import { useMainNavShown, useSearchOpen } from '../mega-menu-provider';

export const NavbarContainer = styled.div<{
  isNavOpen: boolean;
  isDesktopFlyoutOpen: boolean;
  isMainNavShown: boolean;
  isPageScrolled: boolean;
  isSearchOpen: boolean;
}>(({ isNavOpen, isDesktopFlyoutOpen, isMainNavShown, isPageScrolled, isSearchOpen, theme }) => {
  const isNavigationOpen = isNavOpen || isDesktopFlyoutOpen;

  return css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: ${theme.colors.white};
    z-index: 99;
    box-sizing: border-box;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${isNavigationOpen ? theme.colors.cl08 : 'transparent'};

    @media ${theme.device.large} {
      box-shadow: ${isMainNavShown || isSearchOpen
        ? 'none'
        : '0px 3px 12px 0px rgba(0, 0, 0, 0.2)'};
      border-bottom-width: ${isPageScrolled && isNavigationOpen ? '1px' : 0};
      ${isPageScrolled && isNavigationOpen && `border-bottom-color: ${theme.colors.cl08}`};

      ${StyledLink} {
        font-size: ${theme.fontSizes[2]}px;
        line-height: ${theme.lineHeights[1]}px;
        font-weight: ${theme.fontWeights.medium};
      }
    }

    --tooltip-bottom: 0.75rem;

    [aria-label] {
      position: relative;
      ::before,
      ::after {
        .mm-sticky & {
          display: none;
        }
      }
    }

    [aria-label]::before {
      content: '';
      opacity: 0;
      pointer-events: none;
      position: absolute;
      padding: 0;

      bottom: calc(-1 * var(--tooltip-bottom));
      left: 50%;
      transform: translateX(-0.5rem);
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid ${theme.colors.black};
      border-left: 0.5rem solid transparent;
      transition: opacity 0.2s;
    }
    [aria-label]::after {
      content: attr(aria-label); // tooltip text
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      width: max-content;

      bottom: 0;
      left: 50%;
      display: flex;
      padding: 0.25rem 0.375rem;
      border-radius: 0.25rem;
      transition: opacity 0.2s;
      transform: translate(-50%, calc(100% + var(--tooltip-bottom)));
      background-color: ${theme.colors.black};
      color: ${theme.colors.white};
    }
    [aria-label]:hover::after,
    [aria-label]:hover::before {
      transition: opacity 0.2s 1s;
      opacity: 1;
      pointer-events: auto;
    }
  `;
});

export const NavbarInnerContainer = styled.nav<{ isNavOpen: boolean }>`
  width: 100%;
  top: 0;
  padding: 0.75rem 0rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.cl01};
  box-sizing: border-box;
  height: 4.25rem;

  @media ${({ theme }) => theme.device.large} {
    padding: 0.75rem 0rem;
  }

  ${media.noTouch`
    height: 4rem;
  `}
`;

export const LogoContainer = styled.div`
  max-width: 11.5625rem;
  max-height: 2.5rem;
  margin-right: auto;

  img {
    height: 1rem;
    width: auto;
  }

  @media ${({ theme }) => theme.device.large} {
    img {
      height: 1.25rem;
    }
  }
`;

export const IconsWrapper = styled.div<{ showOnlyIcons: boolean }>(({ theme, showOnlyIcons }) => {
  return css`
    display: flex;

    .icons {
      .user-icon,
      .basket-icon {
        display: none;
        position: relative;
        .cart-counter {
          position: ${showOnlyIcons ? 'absolute' : 'relative'};
          right: ${showOnlyIcons ? '1px' : 0};
          top: ${showOnlyIcons ? '1px' : 0};
          margin: ${showOnlyIcons ? '0' : '0.5rem'};
        }
      }
    }

    @media ${theme.device.medium} {
      .icons {
        .user-icon,
        .basket-icon {
          display: block;
        }
      }
    }

    &&&& div button {
      ${showOnlyIcons ? 'padding-right: 0' : ''};
    }

    @media ${theme.device.large} {
      column-gap: ${!showOnlyIcons ? '0.5rem' : 0};
      transition: column-gap var(--meta-nav-animation-duration) ease;

      ${OnlineCatalogButton} {
        ${!showOnlyIcons && 'margin-left: 1rem'};
      }
    }

    &&& a.with-text {
      width: unset;
      max-width: 10rem;
      padding-left: 2.5rem;

      @media ${theme.device.large} {
        padding-right: 0.5rem;
      }
    }

    &&& .label {
      height: unset;
      width: unset;
      max-width: 10rem;
      display: none;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @media ${theme.device.large} {
        display: block;
      }
    }
  `;
});

export const IconContainer = styled.div<{ showOnMobile?: boolean }>`
  ${({ theme, showOnMobile = true }) => {
    return css`
      display: ${showOnMobile ? 'flex' : 'none'};
      align-items: center;

      ${StyledButton} {
        &.initials {
          padding-right: 0;
        }
      }

      ${StyledLink} {
        width: 2.5rem;
        padding: 0;
        ${media.touch`width: 2.75rem;`}
      }

      &&& button:not(.initials) {
        ${media.touch`height: 2.75rem; min-width: 2.75rem;}`}
        span:not(.label) {
          :first-of-type {
            ${media.touch`width: 2.75rem;min-width: 2.75rem;height: 2.75rem;`}
          }

          [data-preview-id] {
            width: unset;
          }
        }

        svg {
          fill: ${theme.colors.black};
        }

        & .selected {
          background-color: rgba(${theme.colors.transparentBlack}, 0.14);
          color: ${theme.colors.black};

          & path {
            fill: ${theme.colors.black};
          }
        }
      }

      @media ${theme.device.large} {
        &&& button {
          padding-right: 0.5rem;
          min-width: 2.5rem;
          transition:
            padding-right var(--meta-nav-animation-duration) ease,
            min-width var(--meta-nav-animation-duration) ease;
        }
      }

      svg {
        fill: ${theme.colors.cl16};
        width: 1.25rem;
      }
      ${media.hover`
        svg {
          fill: ${theme.colors.cl16};
        }
      `}
      &:active {
        &&& button {
          background-color: ${theme.colors.cl15};
          color: ${theme.colors.white};
          div:not(.cart-counter),
          div:not(.cart-counter) label {
            background-color: ${theme.colors.cl15};
          }
          svg path {
            fill: ${theme.colors.white};
          }
        }
      }
      &:focus-visible {
        border: 1px solid ${theme.colors.primaryLight};
        outline: 1px solid ${theme.colors.primaryLight};
        text-decoration: none;
      }

      &.close-icon {
        @media ${({ theme }) => theme.device.large} {
          display: none;
        }
      }

      &.basket-icon {
        border: 1px solid transparent;

        &:focus {
          border: 1px solid ${theme.colors.primaryLight};
          outline: 1px solid ${theme.colors.primaryLight};
        }
      }
    `;
  }}
`;

export const IconsContainer = styled.div<{ showOnlyIcons: boolean; showOnMobile: boolean }>`
  ${({ theme, showOnlyIcons, showOnMobile }) => {
    return css`
      justify-self: end;
      align-items: center;
      right: 1rem;
      display: ${showOnMobile ? 'flex' : 'none'};

      @media ${theme.device.large} {
        display: flex;
        column-gap: ${showOnlyIcons ? '0' : '0.5rem'};
        transition: column-gap var(--meta-nav-animation-duration) ease;
      }
    `;
  }}
`;

export const AnimationWrapperIcons = styled.div<{ showOnlyIcons: boolean }>`
  ${({ showOnlyIcons }) => {
    return css`
      display: flex;
      line-height: 1.5rem;
      overflow: hidden;
      min-width: ${showOnlyIcons && 0};
      max-width: ${showOnlyIcons && 0};
      transition:
        min-width,
        max-width,
        var(--meta-nav-animation-duration) ease;
      align-items: center;
      .label {
        opacity: ${showOnlyIcons ? 0 : 1};
        transition: opacity var(--meta-nav-animation-duration) ease;
      }
    `;
  }}
`;

export const LocatorPinContainer = styled.div<{
  selected: boolean;
  enabled: boolean;
}>`
  ${({ theme, enabled }) => {
    return css`
      position: relative;
      display: none;
      border: 1px solid transparent;

      @media (width >= 23.4375rem) {
        display: ${enabled ? 'initial' : 'none'};
      }

      &:focus {
        border: 1px solid ${theme.colors.primaryLight};
        outline: 1px solid ${theme.colors.primaryLight};
      }
    `;
  }}
`;

export const ProfileContainer = styled.div<{ isActive: boolean; showMobile?: boolean }>`
  ${({ theme, isActive, showMobile }) => {
    return css`
      position: relative;
      ${isActive && `background-color: rgba(${theme.colors.transparentBlack}, 0.14);`}
      display: ${showMobile ? 'initial' : 'none'};
      border: 1px solid transparent;

      @media ${theme.device.medium} {
        display: initial;
      }

      &:focus {
        border: 1px solid ${theme.colors.primaryLight};
        outline: 1px solid ${theme.colors.primaryLight};
      }
    `;
  }}
`;

export const SearchWrapper = styled.div(({ theme }) => {
  return css`
    border: 1px solid transparent;

    &:focus {
      border: 1px solid ${theme.colors.primaryLight};
      outline: 1px solid ${theme.colors.primaryLight};
    }
  `;
});

export const SearchBarWrapperMobile = styled.div<{ show: boolean }>(({ theme, show }) => {
  return css`
    left: 0;
    right: 0;
    top: 100%;
    margin: auto;
    position: absolute;

    height: ${show ? '100vh' : '0'};
    transition: all 0.3s ease;
    transition-delay: ${show ? '0' : '0.3s'};
    background-color: ${theme.colors.cl01};
    z-index: 102;

    padding: 0 1rem;
    border-top: 1px solid ${theme.colors.cl08};

    @media ${theme.device.medium} {
      padding: 0 6.25rem;
    }

    @media ${theme.device.large} {
      display: none;
    }
  `;
});

export const SearchBarWrapper = styled.div<{ show: boolean }>(({ theme, show }) => {
  const { isMainNavShown } = useMainNavShown();

  return css`
    width: 100%;
    display: none;
    grid-template-rows: ${show ? '1fr' : '0fr'};
    transition: grid-template-rows 0.3s ease;
    border-top-width: ${show ? '1px' : '0px'};
    border-top-style: solid;
    border-top-color: ${theme.colors.cl08};
    padding: 0;
    margin: 0;
    box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.3);

    ${Container} {
      position: relative;
    }

    ${SearchBarWrapperInner} {
      ${!show && 'overflow: hidden'};
    }

    @media ${theme.device.large} {
      display: grid;
      position: fixed;
      ${!isMainNavShown && 'top: 4rem;'}
      ${media.touch`${!isMainNavShown && 'top: 4.25rem;'}`}
      background-color: ${theme.colors.white};
    }
  `;
});

export const SearchBarWrapperInner = styled.div(() => {
  const { isSearchOpenEnd } = useSearchOpen();
  return css`
    position: relative;
    ${!isSearchOpenEnd && 'overflow: hidden'};
  `;
});

export const CloseIcon = styled.span<{ leftOffset?: `${number}${'px' | 'rem'}` }>`
  ${({ theme, leftOffset = '0px' }) => {
    return css`
      @media ${theme.device.large} {
        position: absolute;
        z-index: 100;
        top: 1rem;
        right: ${leftOffset};
        svg {
          width: 1.25rem;
        }
      }
    `;
  }}
`;
