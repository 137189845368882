import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import striptags from 'striptags';

// components
import SearchAutocomplete from 'components/SearchBar/SearchAutocomplete';

// selectors
import { globalsSearchSelector } from 'utils/selectors/globalsSelectors';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';

// actions
import { NavigationContext, NAVIGATION_SEARCH_OPEN } from '../NavigationContext';
import { useTranslationByKey } from 'utils/hooks/use-translations';

interface OverlayProps {
  isMobile?: boolean;
  searchInputRef?: HTMLInputElement | null;
}

interface SearchConfig {
  articlesDisabled: boolean;
  contentDisabled: boolean;
  searchDisabled: boolean;
  segment: string;
  search?: string;
  searchSegment?: string;
  searchResultPageUrl: string;
  filters: string[];
}

export default function Overlay({
  isMobile = false,
  searchInputRef = null,
}: Readonly<OverlayProps>) {
  const { state, dispatch } = useContext(NavigationContext) || {};
  const router = useRouter();
  const searchConfig: SearchConfig | null = useSelector(globalsSearchSelector);
  const searchPlaceholder = useTranslationByKey('web20_search_placeholder');
  const searchOpen = state?.searchOpen;

  if (!searchConfig || !dispatch) return null;

  const handleSearchSubmit = (e) => {
    const value = typeof e !== 'string' ? e.target.value : e;

    if (value) {
      dispatch({ type: NAVIGATION_SEARCH_OPEN, open: false });

      // redirect after animation is finished
      setTimeout(() => {
        const query = encodeURIComponent(striptags(value));
        router.push(`${searchConfig.searchResultPageUrl}?q=${query}`);
      }, 500);
    }
  };

  const closeSearch = () => {
    dispatch({ type: NAVIGATION_SEARCH_OPEN, open: false });
  };

  return (
    <form
      className={classNameBuilder(
        'c-search-bar-form c-search-bar-form--white-bg',
        searchOpen && 'c-search-bar-form--white-bg-active',
      )}
      onSubmit={(e) => e.preventDefault()}
      action={searchConfig.searchResultPageUrl}
    >
      <SearchAutocomplete
        id="header-search"
        handleSubmit={handleSearchSubmit}
        segment={searchConfig.searchSegment}
        searchPlaceholder={searchPlaceholder}
        closeSearchOverlay={closeSearch}
        showSearch={searchOpen}
        isMobile={isMobile}
        hasAnimation
        searchInputRef={searchInputRef}
      />
    </form>
  );
}
