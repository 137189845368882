import { useEffect } from 'react';
import { Button, Container as GridContainer } from '@geberit/gdds';

// components
import { Search } from '../search/search';
import { AnimatedLogo } from 'components/Logo/animated-logo';
import { MobileNavigation } from './mobile/mobile-navigation';
import { DesktopNavigation } from './desktop/desktop-navigation';
import { FocusVisibleManager } from 'utils/focus-visible';

// styles
import { Container, SearchWrapper, SearchContainer } from './main-nav.styles';
import { CloseIcon, SearchBarWrapper, SearchBarWrapperInner } from '../navbar/navbar.styles';

// utils
import {
  useDesktopFlyoutOpen,
  useMainNavShown,
  useMegaMenuRefs,
  useSearchOpen,
} from '../mega-menu-provider';
import { useOnEscapePress } from '../hooks/use-key-press';
import { useIsDesktop } from 'components/App/SizeProvider';
import { useClientSideRender } from 'utils/hooks/use-client-side-render';

export function MainNav() {
  const { setIsDesktopFlyoutOpen, isDesktopFlyoutOpen } = useDesktopFlyoutOpen();
  const { isMainNavShown } = useMainNavShown();
  const { mainNavRef } = useMegaMenuRefs();
  const { isSearchOpen, setIsSearchOpen } = useSearchOpen();
  const isDesktop = useIsDesktop();
  const isClient = useClientSideRender();

  useEffect(() => {
    if (isSearchOpen) {
      document.querySelector('main')?.classList.add('search-open');
    } else {
      document.querySelector('main')?.classList.remove('search-open');
    }

    if (isMainNavShown) {
      document.querySelector('main')?.classList.remove('mm-sticky');
      document.getElementById('mega-menu')?.classList.remove('mm-sticky');
    } else {
      document.querySelector('main')?.classList.add('mm-sticky');
      document.getElementById('mega-menu')?.classList.add('mm-sticky');
    }
  }, [isSearchOpen, isMainNavShown]);

  useOnEscapePress(() => {
    setIsDesktopFlyoutOpen(false);
    setIsSearchOpen(false);
  });

  useEffect(() => {
    if (isDesktop) {
      if (mainNavRef.current) {
        mainNavRef.current.style.height = '';
        mainNavRef.current.style.minHeight = '';
      }
      return;
    }

    if (!mainNavRef.current?.firstChild) {
      return;
    }

    const observer = new ResizeObserver(([{ contentRect }]) => {
      if (mainNavRef.current) {
        mainNavRef.current.style.height = `${contentRect.height}px`;
        mainNavRef.current.style.minHeight = `${contentRect.height}px`;
      }
    });

    observer.observe(mainNavRef.current?.firstChild as Element);

    return () => {
      observer.disconnect();
    };
  }, [mainNavRef, isDesktop]);

  return (
    <Container expand={isDesktopFlyoutOpen} ref={mainNavRef}>
      <GridContainer maxContentWidth="80rem">
        <MobileNavigation />
        <DesktopNavigation />
      </GridContainer>

      <SearchBarWrapper show={isSearchOpen}>
        <SearchBarWrapperInner>
          <GridContainer maxContentWidth="80rem">
            <CloseIcon leftOffset="1.5rem">
              <Button
                onClick={() => {
                  setIsSearchOpen(false);
                }}
                isIcon
                symbol="close"
                stylingType="icon"
              />
            </CloseIcon>
            <SearchContainer>
              <SearchWrapper>
                <FocusVisibleManager>
                  <Search className="desktop-search" />
                </FocusVisibleManager>
              </SearchWrapper>
              {isClient && <AnimatedLogo className="search-animated-logo" />}
            </SearchContainer>
          </GridContainer>
        </SearchBarWrapperInner>
      </SearchBarWrapper>
    </Container>
  );
}
