import { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';

// components
import CiamLoginView from './ciam-login-view';
import CiamGddsLoginView from './ciam-login-gdds-view';

// utils
import { SessionContext } from 'components/Session/SessionContext';
import { useIsDesktop, useIsMobile, useIsTablet } from 'components/App/SizeProvider';
import { useNord } from 'utils/hooks/use-nord';
import { useGdds } from 'utils/hooks/use-gdds';
import { useIsCC } from 'utils/hooks/use-is-cc';
import { useCiamLogin } from 'utils/hooks/useCiam';
import { ciamProfileUrlSelector, orderHistoryUrlSelector } from 'utils/selectors/globalsSelectors';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { Log } from 'services/log';

interface CiamLoginProps {
  opened?: boolean;
  toggleCiamFlyout?: (boolean) => void;
  addActiveClass?: (string) => void;
}

export default function CiamLogin({
  addActiveClass,
  opened = false,
  ...props
}: Readonly<CiamLoginProps>) {
  const isContentCreator = useIsCC();
  const isNord = useNord();
  const isGdds = useGdds();
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const language = useCurrentLanguage();
  const ciamProfileUrl: string | null = useSelector(ciamProfileUrlSelector);
  const orderHistoryUrl: string | null = useSelector(orderHistoryUrlSelector);

  const ciamDiv = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const {
    state: { gigyaWindow },
  } = useContext(SessionContext);
  const { loggedIn, logout, login } = useCiamLogin(gigyaWindow);

  const toHistory = {
    target: orderHistoryUrl,
    type: 'internal_link',
  };

  const country = language.slice(language.length - 2);

  const handleClickOutside = useCallback(
    (event) => {
      if (!isContentCreator && !ciamDiv.current?.contains(event.target)) {
        setIsOpen(false);
        if (opened && typeof props.toggleCiamFlyout === 'function') props.toggleCiamFlyout(false);
        if (typeof addActiveClass === 'function') addActiveClass('');
      }
    },
    [isContentCreator, opened, props, addActiveClass],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const openLogin = useCallback(() => {
    window.sessionStorage.removeItem('ciam_redirect_to');
    window.sessionStorage.removeItem('ciam_redirect_open');
    login(language, `${country}_WebsitePlatform`);
  }, [country, language, login]);

  const openRegistration = useCallback(() => {
    Log.error('Called deprecated function openRegistration in ciam-login.tsx');
  }, []);

  const ciamProfileLink = {
    target: ciamProfileUrl,
    type: 'internal_link',
    showArrow: false,
  };

  if (isNord || (isGdds && isDesktop)) {
    return (
      <CiamGddsLoginView
        {...props}
        opened={opened}
        openLogin={openLogin}
        openRegistration={openRegistration}
        toHistory={toHistory}
        ciamProfileUrl={ciamProfileUrl}
        logout={logout}
        loggedIn={loggedIn}
        ciamDiv={ciamDiv}
      />
    );
  }

  return (
    <CiamLoginView
      {...props}
      {...{ isMobile, isDesktop, isTablet }}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      ciamDiv={ciamDiv}
      openLogin={openLogin}
      toHistory={toHistory}
      loggedIn={loggedIn}
      logout={logout}
      ciamProfileLink={ciamProfileLink}
    />
  );
}
