import { useEffect } from 'react';
import { useRouter } from 'next/navigation';

// Utils
import { PreviewContextProvider } from './PreviewContext';
import { useIsPreview } from 'utils/hooks/useIsPreview';

export function PreviewListener({ children }: Readonly<React.PropsWithChildren>) {
  const router = useRouter();
  const isPreview = useIsPreview();

  useEffect(() => {
    const previewCallbackHandler = (msg) => {
      switch (msg.data) {
        case 'showTranslationPage':
          router.push('/_translations');
          break;
        case 'showEditorPage':
          router.push('/_editors');
          break;
        case 'showShortUrlsPage':
          router.push('/_shorturls');
          break;
        case 'showDownloadcenterPage':
          router.push('/_downloads');
          break;
        case 'showEventsPage':
          router.push('/_events');
          break;
        default:
          break;
      }
    };

    if (isPreview) {
      window.addEventListener('message', previewCallbackHandler);
    }

    return () => {
      if (isPreview) {
        window.removeEventListener('message', previewCallbackHandler);
      }
    };
  }, [isPreview, router]);

  if (isPreview) {
    return <PreviewContextProvider>{children}</PreviewContextProvider>;
  }

  return children;
}
