import React from 'react';

// styles
import styles from './overlay.module.scss';

function Overlay() {
  return <div className={styles.overlay} />;
}

export default Overlay;
