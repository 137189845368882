'use client';

import { useReducer, createContext } from 'react';
import { usePathname } from 'next/navigation';
import produce from 'immer';
import isEqual from 'lodash.isequal';

// utils
import { useContextValue } from 'utils/hooks/use-context-value';
import { useContent } from 'components/Page/page-hooks';

export const UPDATE_CLICKPATH = 'nav/updateClickPath';
export const SET_OVERRIDEPATH = 'nav/overridePath';
export const UPDATE_HEIDER_HEIGHT = 'nav/updateHeaderHeight';
export const UPDATE_NAVIGATION_FLYOUT = 'nav/updateFylout';
export const NAVIGATION_OPEN = 'nav/open';
export const NAVIGATION_CLOSED = 'nav/closed';
export const NAVIGATION_SEARCH_OPEN = 'nav/search';
export const UPDATE_LANGUAGESHOWN = 'nav/updatelangshown';

const NOHEADER_PAGETYPES = ['content_page_no_header_footer'];

interface State {
  nextPath: any[];
  clickPath: any[];
  overridePath: any[];
  headerHeight: number;
  flyoutState: string;
  searchOpen: boolean;
  languageShown: boolean;
}

const initialState: State = {
  nextPath: [],
  clickPath: [],
  overridePath: [],
  headerHeight: 0,
  flyoutState: NAVIGATION_CLOSED,
  searchOpen: false,
  languageShown: false,
};

export const NavigationContext = createContext<{
  state: State;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const navigationReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_OVERRIDEPATH:
      if (!isEqual(draft.overridePath, action.overridePath)) {
        draft.overridePath = action.overridePath;
      }
      break;

    case UPDATE_LANGUAGESHOWN:
      draft.languageShown = action.state;
      break;

    case UPDATE_CLICKPATH:
      draft.clickPath = action.clickPath;
      draft.nextPath = action.clickPath;
      break;

    case UPDATE_NAVIGATION_FLYOUT:
      draft.flyoutState = action.state;
      draft.searchOpen = false;
      if (action.state === NAVIGATION_CLOSED) {
        draft.languageShown = false;
        if (draft.overridePath && draft.overridePath.length > 1) {
          draft.clickPath = draft.overridePath.slice(0, draft.overridePath.length - 1);
        }
      }
      break;

    case UPDATE_HEIDER_HEIGHT:
      draft.headerHeight = action.height;
      break;

    case NAVIGATION_SEARCH_OPEN:
      draft.flyoutState = NAVIGATION_CLOSED;
      draft.searchOpen = action.open;
      if (action.open) {
        draft.clickPath = [];
      }
      break;

    default:
  }
}, initialState);

export function NavigationContextProvider({
  children,
  initialPage,
}: Readonly<React.PropsWithChildren<{ initialPage: Content }>>) {
  const content = useContent();
  const reducer = useReducer(navigationReducer, initialState);
  const value = useContextValue(reducer);
  const pageHasHeader =
    usePathname() !== '/500' && !NOHEADER_PAGETYPES.includes((content ?? initialPage)?.type ?? '');
  if (!pageHasHeader) return null;

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
}
