import React from 'react';
import { useSelector } from 'react-redux';

// gdds components
import { Divider, Container } from '@geberit/gdds';

// components
import { Translation } from 'components/Translation/Translation';
import { LanguageSwitch } from './language-switch';
import { NavButton } from '../common/nav-button';

// styles
import { FlyoutWrapper } from '../locator-switch/locator-selector.styles';
import { HorizontalMarginWrapper, BackButton, AnimationLayer } from '../common/common.styles';

// utils
import currentLanguageSelector from 'utils/selectors/languageSelectors';
import { alternativePageLanguagesSelector } from 'utils/selectors/pageSelectors';
import { useAnimate } from '../hooks/use-animate';

const getLanguageLabel = (availableLanguages: Language[], currentLanguage: string | null) => {
  const currentLangObject = availableLanguages.filter((languageObject) => {
    return languageObject.lang === currentLanguage;
  });
  const languageLabel = currentLangObject[0]?.labelFull.split(' - ')[1];
  return languageLabel;
};

export function LanguageSelector() {
  const availableLanguages = useSelector(alternativePageLanguagesSelector);
  const currentLanguage = useSelector(currentLanguageSelector);
  const { closeFlyout, openFlyout, flyoutOpen, animationType, onAnimationEnd } = useAnimate();

  if (!availableLanguages || availableLanguages.length === 1) {
    return null;
  }

  return (
    <Container maxWidth="80rem">
      <HorizontalMarginWrapper>
        <Divider />
      </HorizontalMarginWrapper>
      <NavButton
        className="language-switch-trigger"
        rightIconName="chevron_right"
        onClick={openFlyout}
      >
        {getLanguageLabel(availableLanguages, currentLanguage)}
      </NavButton>
      {flyoutOpen && (
        <FlyoutWrapper>
          <AnimationLayer animationType={animationType} onAnimationEnd={onAnimationEnd}>
            <Container maxContentWidth="80rem">
              <BackButton leftIconName="chevronleft" onClick={closeFlyout}>
                <Translation id="web20_language_selector_headline" />
              </BackButton>
              <LanguageSwitch items={availableLanguages} />
            </Container>
          </AnimationLayer>
        </FlyoutWrapper>
      )}
    </Container>
  );
}
