import { useSelector } from 'react-redux';

// utils
import { currentPageSelector } from './selectors/pageSelectors';
import { isEmpty } from 'utils/is-empty';
import { useIsPreview } from 'utils/hooks/useIsPreview';

export function usePage<T = {}>(pageFromServer: T): T | null {
  const isPreview = useIsPreview();
  const page = useSelector(currentPageSelector);

  if (isPreview) {
    if (isEmpty(page)) {
      return null;
    }

    // some pages, for example search_results, do not have a page object (page.page will throw an error)
    // to prevent errors on page transitions in preview mode
    // we need to check if the page object exists, otherwise we set it to empty object
    // in live mode we don't need to check this because page object always exist in pageFromServer

    // example: if we are on search_results page, an empty page will be stored in the redux store
    // if we navigate for example to locator page, we read (before the PageFromServer is set in
    // the useEffect hook) an empty object for page (page not has a attribute page) from the redux store
    page.page = page.page || {};

    return page as T;
  }

  return pageFromServer;
}
