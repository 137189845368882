import React, { HTMLProps, PropsWithChildren, forwardRef } from 'react';
import { Icon } from '@geberit/gdds';
import { StyledLink, OverviewArrowWrapper } from './mobile-nav-item.styles';
import type { UrlObject } from 'url';

type NavItemProps = PropsWithChildren<{
  href: UrlObject | string;
  isOption?: boolean;
  selected?: boolean;
  shallow?: boolean;
  leftIconUrl?: string | false;
  leftIconName?: string | false;
  rightIconName?: string | false;
  hasPictogram?: boolean;
  withPictogram?: boolean;
  firstLevel?: boolean;
  thirdLevel?: boolean;
  isOverviewItem?: boolean;
  notClickable?: boolean;
}> &
  HTMLProps<HTMLAnchorElement>;

export const NavItem = forwardRef<HTMLAnchorElement, NavItemProps>(
  (
    {
      selected,
      leftIconUrl,
      leftIconName,
      rightIconName,
      hasPictogram,
      withPictogram,
      firstLevel,
      thirdLevel,
      shallow,
      children,
      isOption,
      as,
      isOverviewItem,
      notClickable,
      ...anchorProps
    },
    ref,
  ) => {
    function getIconPosition() {
      if ((leftIconName || leftIconUrl) && rightIconName) {
        return 'both';
      }
      if (leftIconName || leftIconUrl) {
        return 'left';
      }
      if (rightIconName) {
        return 'right';
      }
      return 'none';
    }

    return (
      <StyledLink
        ref={ref}
        selected={selected}
        className={selected ? 'selected' : undefined}
        shallow={shallow}
        $notClickable={notClickable}
        $isOption={isOption}
        $iconPosition={getIconPosition()}
        $hasPictogram={withPictogram && !isOverviewItem}
        $firstLevel={firstLevel}
        $thirdLevel={thirdLevel}
        $isOverviewItem={isOverviewItem}
        {...anchorProps}
      >
        {leftIconName && <Icon className="first-icon" symbol={leftIconName} />}
        {withPictogram ? (
          <div className="pictogram">{leftIconUrl && <img src={leftIconUrl} alt="" />}</div>
        ) : (
          leftIconUrl && <img src={leftIconUrl} alt="" />
        )}
        {isOverviewItem && (
          <OverviewArrowWrapper>
            <svg
              className="overview-icon"
              width="28"
              height="18"
              viewBox="0 0 28 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 9H27M27 9L19.2353 1M27 9L19.2353 17" />
            </svg>
          </OverviewArrowWrapper>
        )}
        {children}
        {rightIconName && <Icon className="last-icon" symbol={rightIconName} />}
      </StyledLink>
    );
  },
);
