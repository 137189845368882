import { useSelector } from 'react-redux';
import { Button } from '@geberit/gdds';

// components
import { Translation } from '../Translation/Translation';

// utils
import { useTracking } from 'utils/hooks/useTracking';

// selectors
import { siteShortcutTrackingAction } from 'features/header/trackingActions';
import { groupDataSelector } from 'utils/selectors/globalsSelectors';

// styles
import styles from './styles.module.scss';
import { useRouter } from 'next/navigation';

interface DownloadCenterButtonProps {
  closeFlyout: () => void;
}

function DownloadCenterButton({ closeFlyout }: Readonly<DownloadCenterButtonProps>) {
  const groupData = useSelector(groupDataSelector);
  const router = useRouter();
  const track = useTracking();

  const onClickGroupDlc = () => {
    track.trackEvent(siteShortcutTrackingAction('Download Center'));
    if (router.asPath === groupData.downloadcenter.url) {
      closeFlyout();
    } else if (groupData.downloadcenter.target === '_self') {
      router.push(groupData.downloadcenter.url);
    } else window.open(groupData.downloadcenter.url, '_blank');
  };

  return (
    <Button
      onClick={onClickGroupDlc}
      buttonType="button"
      stylingType="inverse"
      className={styles.dcButton}
    >
      <Translation id="web20_ocb_label" />
    </Button>
  );
}

export default DownloadCenterButton;
