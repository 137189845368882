import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import { Avatar, Badge } from '@geberit/gdds';
import key from 'weak-key';

// selectors
import { alternativePageLanguagesSelector } from '../selectors/pageSelectors';
import { navigationMetaItemsSelector } from '../selectors/navigationSelectors';
import {
  ciamEnabledSelector,
  ciamProfileUrlSelector,
  groupDataSelector,
  ocbSelector,
  orderHistoryUrlSelector,
  webshopBasketPageUrlSelector,
  webshopEnabledSelector,
  locatorConfigurationSelector,
} from '../selectors/globalsSelectors';
import { translationByKeySelector } from '../selectors/translationSelectors';
import { isPreviewSelector } from '../selectors/environmentSelectors';
import {
  languageSwitchTrackingAction,
  metaNavigationTrackingAction,
  siteShortcutTrackingAction,
} from '../../components/Navigation/trackingActions';
import { useTracking } from './useTracking';
import { useNord } from './use-nord';
import { useGdds } from './use-gdds';
import { useCiamLogin } from './useCiam';
import { shoppingBasketCountSelector } from '../selectors/shoppingBasketSelector';
import { decodingContent } from '../decodingContent';
import { handlePopup } from '../openInPopup';
import { useTranslationFunction } from './use-translations';
import { useCurrentLanguage } from './useCurrentLanguage';
import { useHeaderPin } from 'utils/hooks/use-header-pin';
import { isEmpty } from '../is-empty';

const cleanLanguageValue = (lang) => {
  if (lang?.match(/[-|_]/)) {
    return lang?.split(/[-|_]/)[0];
  }
  return lang;
};

function asExternalLink(links) {
  if (links.target.includes('https://') || links.target.includes('http://')) {
    if (links.window === '_popup') {
      const windowId = key(links);
      handlePopup(links, windowId);
    } else {
      window.open(links.target, links.window);
    }
  }
}

export function useMetaNaviData(
  isDesktop: boolean,
  isGddsDesktop: boolean,
  openCiamOverlay?: (_: unknown) => boolean,
) {
  const gigyaWindow = typeof window !== 'undefined' ? window['gigya'] : null;
  const { loggedIn, login, logout, profile } = useCiamLogin(gigyaWindow);
  const isNord = useNord();
  const currentLanguage = useCurrentLanguage();
  const languages = useSelector(alternativePageLanguagesSelector);
  const formattedCurrentLanguage = languages?.find(
    ({ lang }) => lang === currentLanguage,
  )?.labelFull;
  const links = useSelector(navigationMetaItemsSelector);
  const isPreview = useSelector(isPreviewSelector);
  const ocb = useSelector(ocbSelector);
  const ocbLabel = useSelector(
    (state: AppState) => ocb?.label && translationByKeySelector(state, ocb.label),
  );
  const ocbUrl = useSelector(
    (state: AppState) => ocb?.linkTarget && translationByKeySelector(state, ocb.linkTarget),
  );
  const orderHistoryUrl = useSelector(orderHistoryUrlSelector);
  const ciamProfileUrl = useSelector(ciamProfileUrlSelector);
  const translate = useTranslationFunction();
  const loginTranslation = translate('web20_ciam_login');
  const logoutTranslation = translate('web20_ciam_logout');
  const orderHistoryTranslation = translate('web20_ciam_order_history');
  const shoppingCartTranslation = translate('web20_metanavigation_shoppingcart');
  const toOverviewTranslation = translate('web20_menu_tooverview');
  const shoppingCartCount = useSelector(shoppingBasketCountSelector);
  const ciamEnabled = useSelector(ciamEnabledSelector);
  const webshopEnabled = useSelector(webshopEnabledSelector);
  const webshopBasketPageUrl = useSelector(webshopBasketPageUrlSelector);
  const groupData = useSelector(groupDataSelector);
  const locator = useSelector(locatorConfigurationSelector);
  const locatorPath = locator?.url;
  const locatorText = locator?.urlSettings.label;
  const router = useRouter();
  const track = useTracking();
  const isGroup = useGdds();
  const headerPinEnabled = useHeaderPin().enabled;

  const toggleLogin = useCallback(() => {
    if (loggedIn) {
      logout();
    } else {
      const country = currentLanguage.slice(currentLanguage.length - 2);
      window.sessionStorage.removeItem('ciam_redirect_to');
      window.sessionStorage.removeItem('ciam_redirect_open');
      login(currentLanguage, `${country}_WebsitePlatform`);
    }
  }, [currentLanguage, loggedIn]);

  const navClick = useCallback(
    (links) => () => {
      links.type != 'external_link' ? router.push(links.target) : asExternalLink(links);
      if (links.text === 'Basket') {
        track.trackEvent(siteShortcutTrackingAction(links.text));
      } else {
        track.trackEvent(metaNavigationTrackingAction(links.text));
      }
    },
    [router, track],
  );

  const changeLanguage = useCallback(
    (nextLanguage) => {
      let predicate = (lang) => lang.labelFull === nextLanguage.content.text;
      if (isNord) {
        predicate = (lang) => lang.labelFull === nextLanguage.title;
      }
      const language = languages?.find(predicate);
      if (!language) return;

      if (language.url) {
        router.push(language.url);
      }

      track.trackEvent(languageSwitchTrackingAction(language.lang));
    },
    [languages, router],
  );

  const onClickGroupDlc = () => {
    track.trackEvent(siteShortcutTrackingAction('Download Center'));
    if (groupData.downloadcenter.target === '_self') {
      router.push(groupData.downloadcenter.url);
    } else window.push(groupData.downloadcenter.url, '_blank');
  };

  const onClickOcb = () => {
    track.trackEvent(siteShortcutTrackingAction('Online Catalog'));
    window.open(ocbUrl, '_blank');
  };

  const formattedLanguages = useMemo(
    () =>
      languages?.map((lang) => ({
        text: lang.labelFull,
        value: cleanLanguageValue(lang.labelFull).trim(),
      })) ?? [],
    [languages],
  );

  const languageSwitchProps = useMemo(() => {
    const baseProps = {
      onClick: changeLanguage,
      currentLanguage: formattedCurrentLanguage,
    };

    if (!isNord) {
      if (formattedLanguages.length < 2) {
        return undefined;
      }

      return {
        ...baseProps,
        languages: formattedLanguages,
      };
    }

    if (isPreview) {
      return {
        ...baseProps,
        backLinkText: toOverviewTranslation,
        languages: formattedLanguages.map((l) => l?.text),
      };
    } else {
      const nonHiddenLanguages = formattedLanguages?.filter((l) => !l.hidden);

      if (nonHiddenLanguages.length < 2) {
        return undefined;
      }

      return {
        ...baseProps,
        backLinkText: toOverviewTranslation,
        languages: nonHiddenLanguages.map((l) => l?.text),
      };
    }
  }, [isNord, isPreview, formattedLanguages, currentLanguage]);

  const computedData = useMemo(
    () => ({
      languageSwitch: languageSwitchProps,
      links: links?.map((links) => ({
        text: decodingContent(links.text),
        onClick: navClick(links),
      })),
      button:
        isGroup && groupData?.downloadcenter
          ? { text: ocbLabel, onClick: onClickGroupDlc }
          : ocb?.visible && { text: ocbLabel, onClick: onClickOcb },
      icons: [
        ciamEnabled && {
          iconName: loggedIn ? 'UserLoggedOn' : 'Profile',
          onClick: () => toggleLogin(),
          className: 'ciam-icon',
        },
        webshopEnabled && {
          iconName: 'Onlineshop',
          onClick: () => navClick(webshopBasketPageUrl, 'Basket'),
        },
      ].filter(Boolean),
      firstIcons: isNord &&
        !isGddsDesktop &&
        headerPinEnabled && [
          {
            iconName: 'Location',
            onClick: () => router.push(locatorPath),
            text: locatorText,
          },
        ],
      secondIcons: [
        // login
        ((isNord && isGddsDesktop) || (!isNord && isDesktop)) &&
          ciamEnabled &&
          loggedIn && {
            iconName: 'Profile',
            onClick: () => (!loggedIn ? toggleLogin() : openCiamOverlay((p) => !p)),
            text: loggedIn ? logoutTranslation : loginTranslation,
          },
        !isGddsDesktop &&
          ciamEnabled &&
          !loggedIn && {
            iconName: 'Profile',
            onClick: () => toggleLogin(),
            text: loggedIn ? logoutTranslation : loginTranslation,
          },
        // profile
        isNord &&
          !isGddsDesktop &&
          ciamEnabled &&
          loggedIn && {
            onClick: () => router.push(ciamProfileUrl),
            text: `${profile?.firstName || ''} ${profile?.lastName || ''}`,
            renderStatusLeft: () => (
              <Avatar
                type="initials"
                text={`${profile?.firstName?.charAt(0) || ''}${profile?.lastName?.charAt(0) || ''}`}
                isTab
              />
            ),
          },
        // basket
        webshopEnabled && {
          iconName: 'Onlineshop',
          text: shoppingCartTranslation,
          onClick: navClick(webshopBasketPageUrl, 'Basket'),
          renderStatusRight: () =>
            shoppingCartCount > 0 && (
              <Badge key="badge" type="numerical" value={shoppingCartCount} color="warning_info" />
            ),
        },
        // history
        !isGddsDesktop &&
          loggedIn &&
          webshopEnabled && {
            iconName: 'Catalogue',
            text: orderHistoryTranslation,
            onClick: () => router.push(orderHistoryUrl),
          },
        // logout
        isNord &&
          !isGddsDesktop &&
          ciamEnabled &&
          loggedIn && {
            iconName: 'LogOut',
            onClick: () => toggleLogin(),
            className: 'ciam-icon',
            text: logoutTranslation,
          },
      ].filter(Boolean),
    }),
    [
      isNord,
      isGroup,
      isDesktop,
      isGddsDesktop,
      ciamEnabled,
      loggedIn,
      webshopEnabled,
      links,
      isNord,
      isGroup,
      profile,
      shoppingCartCount,
      shoppingCartTranslation,
      orderHistoryUrl,
      logoutTranslation,
      toggleLogin,
      ciamProfileUrl,
      loginTranslation,
      webshopBasketPageUrl,
      formattedLanguages,
      formattedCurrentLanguage,
    ],
  );

  // workaround for gdds metanavi bug
  if (isEmpty(languages)) {
    return null;
  }

  return computedData;
}
