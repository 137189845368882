import React from 'react';

// styles
import styles from './fakeshadow.module.scss';

function FakeShadow() {
  return <div className={styles.fakeShadow} />;
}

export default FakeShadow;
