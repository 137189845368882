import styled, { css } from 'styled-components';
import Link from 'next/link';

// utils
import { media } from '../utils/no-touch-device';

type IconPosition = 'left' | 'right' | 'both' | 'none';

const baseStyles = ({ theme, $iconPosition }) => css`
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 2.75rem;
  display: flex;
  align-items: center;
  padding: 0.6875rem 2.75rem 0.6875rem 0.5rem;
  font-size: ${theme.fontSizes[2]}px;
  line-height: ${theme.lineHeights[1]}px;
  font-weight: ${theme.fontWeights.medium};
  border: 1px solid transparent;
  outline-offset: 0;
  transition: none;
  hyphens: none;
  ${$iconPosition === 'left' ? 'padding-left: 2.75rem' : ''};
  ${$iconPosition === 'both' ? 'padding-left: 2.75rem;' : ''};
`;

const spanStyles = ({ isOption, selected }) => css`
  span {
    min-height: unset;

    &.first-icon,
    &.last-icon {
      position: absolute;
      display: flex;
      justify-content: center;
      padding: 0.75rem;

      ${media.noTouch`
        padding: 0.625rem;
      `}

      svg {
        width: 1.25rem;
        visibility: ${(function () {
          if (isOption) {
            if (selected) {
              return 'visible';
            }
            return 'hidden';
          }

          return 'visible';
        })()};
        fill: currentColor;
      }
    }

    &.first-icon {
      left: 0;
      top: 0;
    }

    &.last-icon {
      right: 0;
    }
  }
`;

export const StyledLink = styled(Link)<{
  selected?: boolean;
  $isOption?: boolean;
  $iconPosition: IconPosition;
}>`
  ${({ theme, selected, $iconPosition, $isOption: isOption }) => {
    return css`
      ${baseStyles({
        theme,
        $iconPosition,
      })}

      && {
        color: ${selected ? theme.colors.primaryLight : theme.colors.black};
        border-radius: 0px;
        border-bottom-color: ${selected && !isOption ? theme.colors.primaryLight : 'transparent'};
      }

      ${media.noTouch`
        min-height: 2.5rem;
        padding: 0.5625rem 2.5rem 0.5625rem 0.5rem;
        ${$iconPosition === 'left' ? 'padding-left: 2.5rem;' : ''};
        ${$iconPosition === 'both' ? 'padding-left:2.5rem;' : ''};
      `}

      &:before {
        content: none;
      }

      &&:focus-visible {
        border: 1px solid ${theme.colors.primaryLight};
        outline: 1px solid ${theme.colors.primaryLight};
        text-decoration: none;
      }

      &&:visited:focus {
        text-decoration: none;
      }

      &:focus,
      &:link {
        text-decoration: none;
      }

      ${media.hover`
        text-decoration: none;
        background-color: rgba(${theme.colors.transparentBlack}, 0.08);
      `}

      &:active {
        ${({ theme }) => theme.global.disableTapHighlight}
        text-decoration: none;
        background-color: ${theme.colors.cl15};
        color: ${theme.colors.white};
      }

      ${spanStyles({ isOption, selected })}
    `;
  }}
`;
