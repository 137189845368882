import styled, { css } from 'styled-components';
import Link from 'next/link';
import { media } from 'mm/utils/no-touch-device';
import { clampLine } from 'mm/utils/clamp-line';

// utils

export const StyledLink = styled(Link)<{
  $notClickable?: boolean;
}>`
  ${({ theme, $notClickable: notClickable }) => {
    return css`
      position: relative;
      width: 100%;
      height: fit-content;
      min-height: 2.75rem;
      padding: 1rem 0rem;
      display: flex;
      align-items: center;
      border: none;
      transition: none;
      pointer-events: ${notClickable ? 'none' : 'auto'};
      text-decoration: none;
      hyphens: none;
      ${clampLine(2)}

      && {
        color: ${theme.colors.black};
        line-height: ${theme.lineHeights[2]}px;
        font-size: ${theme.fontSizes[3]}px;
        font-weight: ${theme.fontWeights.normal};
      }

      &&:before {
        content: '';
        height: 1.5rem;
        box-sizing: border-box;
        position: absolute;
        top: 1rem;
        left: 1px;
        right: 1px;
        border: 1px solid ${theme.colors.transparent};
        outline: 1px solid ${theme.colors.transparent};
      }

      :after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        height: 0.25rem;
      }

      &:link {
        text-decoration: none;
      }

      ${media.hover`
        :after {
          background-color: ${theme.colors.grey10};
        }
      `}

      &&:focus-visible {
        &&:before {
          border: 1px solid ${theme.colors.primaryLight};
          outline: 1px solid ${theme.colors.primaryLight};
        }
      }

      :active {
        :after {
          background-color: ${theme.colors.grey15};
        }
      }
    `;
  }}
`;
