import { useRef } from 'react';
import key from 'weak-key';
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown';

// Compoenents
import { BUTTON_TYPES, Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';

// Styles
import styles from './language-selector.module.scss';
import { classNameBuilder } from 'utils/classNameBuilder';

interface LanguageSelectorDesktopProps {
  sortedLanguages: Array<{
    lang: string;
    labelFull: string;
    url: string;
    disabled?: boolean;
  }>;
  language: string;
  clickHandler: (lang: string, url: string) => void;
  isPreview: boolean;
  isCampus?: boolean;
}

export default function LanguageSelectorDesktop({
  sortedLanguages,
  language,
  clickHandler,
  isPreview,
  isCampus = false,
}: Readonly<LanguageSelectorDesktopProps>) {
  const dropdown = useRef<Dropdown | null>(null);

  return (
    <nav>
      <Dropdown
        ref={dropdown}
        className={classNameBuilder(styles.languageSelector, isCampus && 'campus')}
      >
        <DropdownTrigger>{language?.substring(0, 2)}</DropdownTrigger>
        <DropdownContent>
          <ul>
            {sortedLanguages.map((item) => {
              const handleClick = () => {
                dropdown.current?.hide();
                clickHandler(item.lang, item.url);
              };

              const isActive = language === item.lang;
              const disabledClass = item.disabled && !isPreview ? styles.disabledItem : '';
              const activeClass = isActive ? styles.active : '';

              return isActive || (item.disabled && !isPreview) ? (
                <li
                  className={`${styles.languageItem} ${activeClass} ${disabledClass}`}
                  key={key(item)}
                >
                  {activeClass && <Icon symbol="check" />}
                  <span className={styles.control}>{item.labelFull}</span>
                </li>
              ) : (
                <li className={styles.languageItem} key={key(item)}>
                  <Button
                    className={styles.control}
                    type={BUTTON_TYPES.PLAIN}
                    onClick={handleClick}
                    noTracking
                  >
                    {item.labelFull}
                  </Button>
                </li>
              );
            })}
          </ul>
        </DropdownContent>
      </Dropdown>
    </nav>
  );
}
