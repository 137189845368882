import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

// utils
import { useBodyScroll } from 'components/ContentElementsGdds/locator/utils/use-body-scroll';
import { useIsDesktop } from 'components/App/SizeProvider';
import { ANIMATION_DURATION } from './mega-menu-header';

const MetaNavHeight = 64;

export const MegaMenuContext = createContext({} as ReturnType<typeof useValue>);

function useValue() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isDesktopFlyoutOpen, setIsDesktopFlyoutOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMainNavShown, setIsMainNavShown] = useState(true);
  const [isLocatorPageOpen, setIsLocatorPageOpen] = useState(false);
  const [isProfilePageOpen, setIsProfilePageOpen] = useState(false);
  const [shouldShowBackButton, setShouldShowBackButton] = useState(false);
  const [isPageScrolled, setIsPageScrolled] = useState(false);

  const mainNavRef = useRef<HTMLDivElement>(null);
  const navFlyoutWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setPageScrolledOnScroll = () => setIsPageScrolled(window.scrollY > 0);
    document.addEventListener('scroll', setPageScrolledOnScroll);
    return () => document.removeEventListener('scroll', setPageScrolledOnScroll);
  });

  useEffect(() => {
    if (!isNavOpen) {
      setTimeout(() => {
        setIsLocatorPageOpen(false);
        setIsProfilePageOpen(false);
      }, ANIMATION_DURATION);
    }
  }, [isNavOpen]);

  useEffect(() => {
    if (isProfilePageOpen) {
      setIsNavOpen(true);
    }
  }, [isProfilePageOpen]);

  useEffect(() => {
    if (isSearchOpen) {
      setIsNavOpen(false);
      setIsDesktopFlyoutOpen(false);
    }
  }, [isSearchOpen]);

  const handleSetIsLocatorPageOpen = useCallback((isOpen: boolean) => {
    setIsProfilePageOpen(false);
    setIsLocatorPageOpen(isOpen);
  }, []);

  const handleSetIsProfilePageOpen = useCallback((isOpen: boolean) => {
    setIsLocatorPageOpen(false);
    setIsProfilePageOpen(isOpen);
  }, []);

  return {
    isNavOpen,
    setIsNavOpen,
    isMainNavShown,
    setIsMainNavShown,
    isLocatorPageOpen,
    isSearchOpen,
    setIsSearchOpen,
    isDesktopFlyoutOpen,
    setIsDesktopFlyoutOpen,
    shouldShowBackButton,
    setShouldShowBackButton,
    setIsLocatorPageOpen: handleSetIsLocatorPageOpen,
    isProfilePageOpen,
    setIsProfilePageOpen: handleSetIsProfilePageOpen,
    isPageScrolled,
    mainNavRef,
    navFlyoutWrapperRef,
  };
}

export function MegaMenuProvider({ children }: Readonly<PropsWithChildren>) {
  const value = useValue();
  const isDesktop = useIsDesktop({ gdds: true });

  useBodyScroll(!isDesktop && (value.isNavOpen || value.isSearchOpen));

  useEffect(() => {
    if (value.isDesktopFlyoutOpen) {
      value.setIsSearchOpen(false);
    } else if (document.activeElement) {
      document.querySelector<HTMLAnchorElement>('.first-level > li > a.selected')?.focus();
    }
  }, [value.isDesktopFlyoutOpen]);

  useEffect(() => {
    if (!isDesktop) {
      value.setIsMainNavShown(true);
      return;
    }

    if (value.isSearchOpen) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        value.setIsMainNavShown(entry.isIntersecting);
      },
      { rootMargin: `-${MetaNavHeight}px`, threshold: 0 },
    );
    if (value.mainNavRef.current !== null) {
      observer.observe(value.mainNavRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [value, isDesktop]);

  useEffect(() => {
    if (!value.navFlyoutWrapperRef.current) {
      return;
    }

    if (value.isNavOpen && value.navFlyoutWrapperRef.current.scrollTop > 0) {
      value.navFlyoutWrapperRef.current.scrollTo({ top: 0 });
    }
  }, [value.isNavOpen, value.navFlyoutWrapperRef, isDesktop]);

  return <MegaMenuContext.Provider value={value}>{children}</MegaMenuContext.Provider>;
}

export function useNavOpen() {
  const { isNavOpen, setIsNavOpen } = useContext(MegaMenuContext);

  return { isNavOpen, setIsNavOpen };
}

export function useSearchOpen() {
  const { isSearchOpen, setIsSearchOpen } = useContext(MegaMenuContext);
  const [isSearchClosedEnd, setIsSearchClosedEnd] = useState(false);
  const [isSearchOpenEnd, setIsSearchOpenEnd] = useState(false);

  useEffect(() => {
    if (!isSearchOpen) {
      setTimeout(() => {
        setIsSearchClosedEnd(true);
      }, 300);
      setIsSearchOpenEnd(false);
    } else {
      setIsSearchClosedEnd(false);
      setTimeout(() => {
        setIsSearchOpenEnd(true);
      }, 300);
    }
  }, [isSearchOpen]);

  return { isSearchOpen, setIsSearchOpen, isSearchClosedEnd, isSearchOpenEnd };
}

export function useLocatorPageOpen() {
  const { isLocatorPageOpen, setIsLocatorPageOpen } = useContext(MegaMenuContext);

  return { isLocatorPageOpen, setIsLocatorPageOpen };
}

export function useProfilePageOpen() {
  const { isProfilePageOpen, setIsProfilePageOpen } = useContext(MegaMenuContext);

  return { isProfilePageOpen, setIsProfilePageOpen };
}

export function useMainNavShown() {
  const { isMainNavShown } = useContext(MegaMenuContext);

  return { isMainNavShown };
}

export function useDesktopFlyoutOpen() {
  const { isDesktopFlyoutOpen, setIsDesktopFlyoutOpen } = useContext(MegaMenuContext);

  return { isDesktopFlyoutOpen, setIsDesktopFlyoutOpen };
}

export function useIsPageScrolled() {
  const { isPageScrolled } = useContext(MegaMenuContext);

  return { isPageScrolled };
}

export function useMegaMenuRefs() {
  const { mainNavRef, navFlyoutWrapperRef } = useContext(MegaMenuContext);
  return { mainNavRef, navFlyoutWrapperRef };
}
