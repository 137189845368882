import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import { Avatar, Badge } from '@geberit/gdds';

// selectors & actions
import { alternativePageLanguagesSelector } from 'utils/selectors/pageSelectors';
import {
  navigationMetaItemsSelector,
  navigationMetaPreviewIdSelector,
} from 'utils/selectors/navigationSelectors';
import { ocbSelector, pressPortalSelector } from 'utils/selectors/globalsSelectors';
import { translationByKeySelector } from 'utils/selectors/translationSelectors';
import { isPreviewSelector } from 'utils/selectors/environmentSelectors';
import {
  languageSwitchTrackingAction,
  metaNavigationTrackingAction,
  siteShortcutTrackingAction,
} from 'components/Navigation/trackingActions';

// utils
import { decodingContent } from 'utils/decodingContent';
import {
  TPP_TYPE_TRANSLATION,
  TPP_TYPE_META_NAVIGATION,
} from 'components/ContentCreator/tppConstants';
import { useTracking } from 'utils/hooks/useTracking';
import { useIsCC } from 'utils/hooks/use-is-cc';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import {
  asExternalLink,
  cleanLanguageValue,
  useCiamFlyout,
  useMetaNavigationInfo,
  useMetaTranslations,
} from './utils';
import type { LanguageSwitchItem, LanguageSwitchProps, MetaNavNordicsDataProps } from '.';
import { isEmpty } from 'utils/is-empty';

export function useMetaNavNordicsData(
  isGddsDesktop: boolean,
  isTwyford: boolean,
): MetaNavNordicsDataProps | null {
  const currentLanguage = useCurrentLanguage();
  const formattedCurrentLanguage = currentLanguage.split('_')[0];
  const languages = useSelector(alternativePageLanguagesSelector);
  const links = useSelector(navigationMetaItemsSelector);
  const isPreview = useSelector(isPreviewSelector);
  const isCC = useIsCC();
  const previewId = useSelector(navigationMetaPreviewIdSelector);
  const ocb = useSelector(ocbSelector);
  const pressPortal = useSelector(pressPortalSelector);
  const ocbLabel = useSelector(
    (state: AppState) => ocb?.label && translationByKeySelector(state, ocb.label),
  );
  const ocbUrl = useSelector(
    (state: AppState) => ocb?.linkTarget && translationByKeySelector(state, ocb.linkTarget),
  );
  const { loginTranslation, logoutTranslation, orderHistoryTranslation, shoppingCartTranslation } =
    useMetaTranslations();
  const {
    orderHistoryUrl,
    ciamProfileUrl,
    shoppingCartCount,
    ciamEnabled,
    webshopEnabled,
    webshopBasketPageUrl,
    loggedIn,
    login,
    logout,
    profile,
  } = useMetaNavigationInfo();
  const ciamFlyout = useCiamFlyout();

  const router = useRouter();
  const track = useTracking();

  const toggleLogin = useCallback(() => {
    if (loggedIn) {
      logout();
    } else {
      const country = currentLanguage.slice(currentLanguage.length - 2);
      window.sessionStorage.removeItem('ciam_redirect_to');
      window.sessionStorage.removeItem('ciam_redirect_open');
      login(currentLanguage, `${country}_WebsitePlatform`);
    }
  }, [currentLanguage, loggedIn]);

  const navClick = useCallback(
    (link) => () => {
      link.type != 'external_link' ? router.push(link.target) : asExternalLink(link);
      if (link.text === 'Basket') {
        track.trackEvent(siteShortcutTrackingAction(link.text));
      } else {
        track.trackEvent(metaNavigationTrackingAction(link.text));
      }
    },
    [router, track],
  );

  const onClickOcb = () => {
    track.trackEvent(siteShortcutTrackingAction('Online Catalog'));
    window.open(ocbUrl, '_blank');
  };

  const formattedLanguages: LanguageSwitchItem[] = useMemo(
    () =>
      languages?.map((lang) => ({
        text: lang.labelFull,
        value: isTwyford ? lang.lang : cleanLanguageValue(lang.labelFull).trim(),
        hidden: lang.hidden,
      })) ?? [],
    [isTwyford, languages],
  );

  const changeLanguage = useCallback(
    ({ content }) => {
      const language = languages?.find((lang) => lang.labelFull === content.text);
      if (!language) return;

      if (language.url) {
        const currentPath = window.location.pathname;
        const currentParams = window.location.search;
        if (pressPortal?.path && currentPath.includes(pressPortal.path)) {
          const additionalPath = currentPath.substring(currentPath.lastIndexOf('/') + 1);
          router.push(`${language.url}${additionalPath}${currentParams}`);
        } else {
          router.push(language.url);
        }
      }

      track.trackEvent(languageSwitchTrackingAction(language.lang));
    },
    [languages, router, pressPortal, track],
  );

  const languageSwitchProps: LanguageSwitchProps = useMemo(() => {
    const baseProps = {
      onClick: changeLanguage,
      currentLanguage: formattedCurrentLanguage,
    };

    if (isPreview) {
      if (formattedLanguages.length > 1) {
        return {
          ...baseProps,
          languages: formattedLanguages,
        };
      }
    } else {
      const nonHiddenLanguages = formattedLanguages.filter((l: LanguageSwitchItem) => !l.hidden);

      if (nonHiddenLanguages.length > 1) {
        return {
          ...baseProps,
          languages: nonHiddenLanguages,
        };
      }
    }

    return undefined;
  }, [formattedLanguages, formattedCurrentLanguage, isPreview, changeLanguage]);

  const computedData = useMemo(
    () => ({
      languageSwitch: languageSwitchProps,
      links: links?.map((link) => ({
        text: decodingContent(link.text),
        onClick: navClick(link),
        isActive: router.asPath === link.target,
      })),
      previewId: isCC ? previewId : undefined,
      dataEditType: TPP_TYPE_META_NAVIGATION,
      button: ocb?.visible && {
        text: ocbLabel,
        onClick: onClickOcb,
        previewId: `custom:translationKey:${currentLanguage}:web20_ocb_label`,
        dataEditType: TPP_TYPE_TRANSLATION,
      },
      secondIcons: isGddsDesktop
        ? [
            // login
            ciamEnabled &&
              !loggedIn && {
                iconName: 'Profile',
                onClick: () => toggleLogin(),
                text: loginTranslation,
              },
            ciamEnabled &&
              loggedIn && {
                iconName: 'UserLoggedOn',
                onClick: ciamFlyout.toggleFlyout,
                text: logoutTranslation,
                renderElement: ciamFlyout.renderFlyout(),
              },
            // basket
            webshopEnabled && {
              iconName: 'Onlineshop',
              text: shoppingCartTranslation,
              onClick: () => navClick(webshopBasketPageUrl, 'Basket'),
              renderStatusRight: () =>
                shoppingCartCount > 0 && (
                  <Badge key="badge" type="numerical" value={shoppingCartCount} color="warning_info" />
                ),
            },
          ]
        : [
            // login / profile
            ciamEnabled &&
              !loggedIn && {
                iconName: 'Profile',
                onClick: () => toggleLogin(),
                text: loggedIn ? logoutTranslation : loginTranslation,
              },
            ciamEnabled &&
              loggedIn && {
                onClick: () => router.push(ciamProfileUrl),
                text: `${profile?.firstName || ''} ${profile?.lastName || ''}`,
                renderStatusLeft: () => (
                  <Avatar
                    type="initials"
                    text={`${profile?.firstName?.charAt(0) || ''}${
                      profile?.lastName?.charAt(0) || ''
                    }`}
                    isTab
                  />
                ),
              },
            // history
            loggedIn &&
              webshopEnabled && {
                iconName: 'Catalogue',
                text: orderHistoryTranslation,
                onClick: () => router.push(orderHistoryUrl),
              },
            // logout
            ciamEnabled &&
              loggedIn && {
                iconName: 'LogOut',
                onClick: () => toggleLogin(),
                className: 'ciam-icon',
                text: logoutTranslation,
              },
          ].filter(Boolean),
    }),
    [
      isGddsDesktop,
      ciamEnabled,
      loggedIn,
      ciamFlyout.flyoutOpen,
      webshopEnabled,
      links,
      previewId,
      languages,
      profile,
      shoppingCartCount,
      shoppingCartTranslation,
      orderHistoryUrl,
      logoutTranslation,
      toggleLogin,
      ciamProfileUrl,
      loginTranslation,
      webshopBasketPageUrl,
      formattedLanguages,
      formattedCurrentLanguage,
      languageSwitchProps,
    ],
  );

  // workaround for gdds metanavi bug
  if (isEmpty(languages)) {
    return null;
  }

  return computedData;
}
