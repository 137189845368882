import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import { Avatar, Badge } from '@geberit/gdds';

// components
import CiamLogin from 'components/Navigation/MetaNavigation/ciam-login';

// actions & selectors
import { alternativePageLanguagesSelector } from 'utils/selectors/pageSelectors';
import { navigationMetaItemsSelector } from 'utils/selectors/navigationSelectors';
import {
  ciamEnabledSelector,
  ciamProfileUrlSelector,
  groupDataSelector,
  ocbSelector,
  orderHistoryUrlSelector,
  webshopBasketPageUrlSelector,
  webshopEnabledSelector,
} from 'utils/selectors/globalsSelectors';
import { translationByKeySelector } from 'utils/selectors/translationSelectors';
import { isPreviewSelector } from 'utils/selectors/environmentSelectors';
import {
  languageSwitchTrackingAction,
  metaNavigationTrackingAction,
  siteShortcutTrackingAction,
} from 'components/Navigation/trackingActions';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { useNord } from 'utils/hooks/use-nord';
import { useGdds } from 'utils/hooks/use-gdds';
import { useCiamLogin } from 'utils/hooks/useCiam';
import { decodingContent } from 'utils/decodingContent';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { asExternalLink, cleanLanguageValue, onClickGroupDlc } from './utils';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { shoppingBasketCountSelector } from 'utils/selectors/shoppingBasketSelector';
import { isEmpty } from 'utils/is-empty';

export function useMetaNaviData(isDesktop, isGddsDesktop, openCiamOverlay) {
  const isNord = useNord();
  const currentLanguage = useCurrentLanguage();
  const formattedCurrentLanguage =
    currentLanguage &&
    (currentLanguage.includes('_') ? currentLanguage.split('_')[0] : currentLanguage);
  const languages = useSelector(alternativePageLanguagesSelector);
  const links = useSelector(navigationMetaItemsSelector);
  const ocb = useSelector(ocbSelector);
  const ocbLabel = useSelector(
    (state: AppState) => ocb?.label && translationByKeySelector(state, ocb.label),
  );
  const ocbUrl = useSelector(
    (state: AppState) => ocb?.linkTarget && translationByKeySelector(state, ocb.linkTarget),
  );
  const { loginTranslation, logoutTranslation, orderHistoryTranslation, shoppingCartTranslation } =
    useMetaTranslations();
  const {
    orderHistoryUrl,
    ciamProfileUrl,
    shoppingCartCount,
    ciamEnabled,
    webshopEnabled,
    webshopBasketPageUrl,
    groupData,
    loggedIn,
    login,
    logout,
    profile,
  } = useMetaNavigationInfo();

  const router = useRouter();
  const track = useTracking();
  const isGroup = useGdds();

  const toggleLogin = useCallback(() => {
    if (loggedIn) {
      logout();
    } else {
      const country = currentLanguage?.slice(currentLanguage.length - 2);
      window.sessionStorage.removeItem('ciam_redirect_to');
      window.sessionStorage.removeItem('ciam_redirect_open');
      login(currentLanguage, `${country}_WebsitePlatform`);
    }
  }, [currentLanguage, loggedIn]);

  const navClick = useCallback(
    (links) => () => {
      links.type != 'external_link' ? router.push(links.target) : asExternalLink(links);
      if (links.text === 'Basket') {
        track.trackEvent(siteShortcutTrackingAction(links.text));
      } else {
        track.trackEvent(metaNavigationTrackingAction(links.text));
      }
    },
    [router, track],
  );

  const changeLanguage = useCallback(
    ({ content }) => {
      const language = languages?.find((lang) => lang.labelFull === content.text);
      if (!language) return;

      if (language.url) {
        router.push(language.url);
      }

      track.trackEvent(languageSwitchTrackingAction(language?.lang));
    },
    [languages, router],
  );

  const onClickOcb = () => {
    track.trackEvent(siteShortcutTrackingAction('Online Catalog'));
    window.open(ocbUrl, '_blank');
  };

  const formattedLanguages = useMemo(
    () =>
      languages?.map((lang) => ({
        text: lang.labelFull,
        value: cleanLanguageValue(lang.labelFull).trim(),
      })) ?? [],
    [languages],
  );

  const computedData = useMemo(
    () => ({
      languageSwitch:
        formattedLanguages.length > 1
          ? {
              languages: formattedLanguages,
              onClick: changeLanguage,
              currentLanguage: formattedCurrentLanguage,
            }
          : undefined,
      links: links?.map((links) => ({
        text: decodingContent(links.text),
        onClick: navClick(links),
      })),
      button:
        isGroup && groupData?.downloadcenter
          ? {
              text: ocbLabel,
              onClick: () => onClickGroupDlc(router, track, groupData.downloadcenter),
            }
          : ocb?.visible && { text: ocbLabel, onClick: onClickOcb },
      icons: [
        ciamEnabled && {
          iconName: loggedIn ? 'UserLoggedOn' : 'Profile',
          onClick: () => toggleLogin(),
          className: 'ciam-icon',
        },
        webshopEnabled && {
          iconName: 'Onlineshop',
          onClick: () => navClick(webshopBasketPageUrl, 'Basket'),
        },
      ].filter(Boolean),
      secondIcons: [
        // login
        ((isNord && isGddsDesktop) || (!isNord && isDesktop)) &&
          ciamEnabled &&
          loggedIn && {
            iconName: 'Profile',
            onClick: () => (!loggedIn ? toggleLogin() : openCiamOverlay((p) => !p)),
            text: loggedIn ? logoutTranslation : loginTranslation,
          },
        !isGddsDesktop &&
          ciamEnabled &&
          !loggedIn && {
            iconName: 'Profile',
            onClick: () => toggleLogin(),
            text: loggedIn ? logoutTranslation : loginTranslation,
          },
        // profile
        isNord &&
          !isGddsDesktop &&
          ciamEnabled &&
          loggedIn && {
            onClick: () => router.push(ciamProfileUrl),
            text: `${profile?.firstName || ''} ${profile?.lastName || ''}`,
            renderStatusLeft: () => (
              <Avatar
                type="initials"
                text={`${profile?.firstName?.charAt(0) || ''}${profile?.lastName?.charAt(0) || ''}`}
                isTab
              />
            ),
          },
        // basket
        webshopEnabled && {
          iconName: 'Onlineshop',
          text: shoppingCartTranslation,
          onClick: () => navClick(webshopBasketPageUrl, 'Basket'),
          renderStatusRight: () =>
            shoppingCartCount > 0 && (
              <Badge key="badge" type="numerical" value={shoppingCartCount} color="warning_info" />
            ),
        },
        // history
        !isGddsDesktop &&
          loggedIn &&
          webshopEnabled && {
            iconName: 'Catalogue',
            text: orderHistoryTranslation,
            onClick: () => router.push(orderHistoryUrl),
          },
        // logout
        isNord &&
          !isGddsDesktop &&
          ciamEnabled &&
          loggedIn && {
            iconName: 'LogOut',
            onClick: () => toggleLogin(),
            className: 'ciam-icon',
            text: logoutTranslation,
          },
      ].filter(Boolean),
    }),
    [
      isNord,
      isGroup,
      isDesktop,
      isGddsDesktop,
      ciamEnabled,
      loggedIn,
      webshopEnabled,
      links,
      isNord,
      isGroup,
      profile,
      shoppingCartCount,
      shoppingCartTranslation,
      orderHistoryUrl,
      logoutTranslation,
      toggleLogin,
      ciamProfileUrl,
      loginTranslation,
      webshopBasketPageUrl,
      formattedLanguages,
      formattedCurrentLanguage,
    ],
  );

  // workaround for gdds metanavi bug
  if (isEmpty(languages)) {
    return null;
  }

  return computedData;
}

export function useGroupMetaNaviData(isDesktop) {
  const currentLanguage = useCurrentLanguage();
  const formattedCurrentLanguage = currentLanguage.split('_')[0];

  const isPreview = useSelector(isPreviewSelector);
  const languages = useSelector(alternativePageLanguagesSelector);
  const links = useSelector(navigationMetaItemsSelector);
  const ocb = useSelector(ocbSelector);
  const ocbLabel = useSelector(
    (state: AppState) => ocb?.label && translationByKeySelector(state, ocb.label),
  );
  const ocbUrl = useSelector(
    (state: AppState) => ocb?.linkTarget && translationByKeySelector(state, ocb.linkTarget),
  );
  const { loginTranslation, logoutTranslation, shoppingCartTranslation } = useMetaTranslations();
  const {
    orderHistoryUrl,
    ciamProfileUrl,
    shoppingCartCount,
    ciamEnabled,
    webshopEnabled,
    webshopBasketPageUrl,
    groupData,
    loggedIn,
    login,
    logout,
    profile,
  } = useMetaNavigationInfo();
  const ciamFlyout = useCiamFlyout();

  const router = useRouter();
  const track = useTracking();

  const toggleLogin = useCallback(() => {
    if (loggedIn) {
      logout();
    } else {
      const country = currentLanguage.slice(currentLanguage.length - 2);
      window.sessionStorage.removeItem('ciam_redirect_to');
      window.sessionStorage.removeItem('ciam_redirect_open');
      login(currentLanguage, `${country}_WebsitePlatform`);
    }
  }, [currentLanguage, loggedIn]);

  const navClick = useCallback(
    (link) => () => {
      link.type != 'external_link' ? router.push(link.target) : asExternalLink(link);
      if (link.text === 'Basket') {
        track.trackEvent(siteShortcutTrackingAction(link.text));
      } else {
        track.trackEvent(metaNavigationTrackingAction(link.text));
      }
    },
    [router, track],
  );

  const onClickOcb = () => {
    track.trackEvent(siteShortcutTrackingAction('Online Catalog'));
    window.open(ocbUrl, '_blank');
  };

  const formattedLanguages = useMemo(
    () =>
      languages?.map((lang) => ({
        text: lang.labelFull,
        value: cleanLanguageValue(lang.labelFull).trim(),
      })) ?? [],
    [languages],
  );

  const changeLanguage = useCallback(
    ({ content }) => {
      const language = languages?.find((lang) => lang.labelFull === content.text);
      if (!language) return;

      if (language.url) {
        router.push(language.url);
      }

      track.trackEvent(languageSwitchTrackingAction(language.lang));
    },
    [languages, router],
  );

  const languageSwitchProps = useMemo(() => {
    const baseProps = {
      onClick: changeLanguage,
      currentLanguage: formattedCurrentLanguage,
    };

    if (isPreview) {
      if (formattedLanguages.length > 1) {
        return {
          ...baseProps,
          languages: formattedLanguages,
        };
      }
    } else {
      const nonHiddenLanguages = formattedLanguages.filter(
        (l: Record<string, unknown>) => !l.hidden,
      );

      if (nonHiddenLanguages.length > 1) {
        return {
          ...baseProps,
          languages: nonHiddenLanguages,
        };
      }
    }

    return undefined;
  }, [formattedCurrentLanguage, languages, isPreview]);

  const computedData = useMemo(
    () => ({
      languageSwitch: languageSwitchProps,
      links: links?.map((link) => ({
        text: decodingContent(link.text),
        onClick: navClick(link),
        isActive: router.asPath === link.target,
      })),
      button: groupData?.downloadcenter
        ? {
            text: ocbLabel,
            onClick: () => onClickGroupDlc(router, track, groupData.downloadcenter),
          }
        : ocb?.visible && { text: ocbLabel, onClick: onClickOcb },
      icons: [
        ciamEnabled && {
          iconName: loggedIn ? 'UserLoggedOn' : 'Profile',
          onClick: () => toggleLogin(),
          className: 'ciam-icon',
        },
        webshopEnabled && {
          iconName: 'Onlineshop',
          onClick: () => navClick(webshopBasketPageUrl, 'Basket'),
        },
      ].filter(Boolean),
      secondIcons: [
        // login
        ciamEnabled &&
          !loggedIn && {
            iconName: 'Profile',
            onClick: () => toggleLogin(),
            text: loginTranslation,
          },
        ciamEnabled &&
          loggedIn && {
            iconName: 'Profile',
            onClick: () => (!loggedIn ? toggleLogin() : ciamFlyout.toggleFlyout()),
            text: loggedIn ? logoutTranslation : loginTranslation,
            renderElement: ciamFlyout.renderFlyout(),
          },
        // basket
        webshopEnabled && {
          iconName: 'Onlineshop',
          text: shoppingCartTranslation,
          onClick: () => navClick(webshopBasketPageUrl, 'Basket'),
          renderStatusRight: () =>
            shoppingCartCount > 0 && (
              <Badge key="badge" type="numerical" value={shoppingCartCount} color="warning_info" />
            ),
        },
      ].filter(Boolean),
    }),
    [
      isDesktop,
      ciamEnabled,
      loggedIn,
      ciamFlyout.flyoutOpen,
      webshopEnabled,
      links,
      languages,
      profile,
      shoppingCartCount,
      shoppingCartTranslation,
      orderHistoryUrl,
      logoutTranslation,
      toggleLogin,
      ciamProfileUrl,
      loginTranslation,
      webshopBasketPageUrl,
      formattedLanguages,
      formattedCurrentLanguage,
      languageSwitchProps,
    ],
  );

  // workaround for gdds metanavi bug
  if (isEmpty(languages)) {
    return null;
  }

  return computedData;
}

function useCiamFlyout() {
  const ciamEnabled = useSelector(ciamEnabledSelector);
  const webshopEnabled = useSelector(webshopEnabledSelector);
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const toggleFlyout = () => setFlyoutOpen((p) => !p);
  const renderFlyout = useCallback(() => {
    if (!ciamEnabled) return null;

    return (
      <CiamLogin
        opened={flyoutOpen}
        toggleCiamFlyout={toggleFlyout}
        webshopEnabled={webshopEnabled}
      />
    );
  }, [ciamEnabled, webshopEnabled, flyoutOpen]);

  return { renderFlyout, flyoutOpen, toggleFlyout };
}

function useMetaTranslations() {
  const translate = useTranslationFunction();
  const loginTranslation = translate('web20_ciam_login');
  const logoutTranslation = translate('web20_ciam_logout');
  const orderHistoryTranslation = translate('web20_ciam_order_history');
  const shoppingCartTranslation = translate('web20_metanavigation_shoppingcart');

  return {
    loginTranslation,
    logoutTranslation,
    orderHistoryTranslation,
    shoppingCartTranslation,
  };
}

export function useMetaNavigationInfo() {
  const gigyaWindow = typeof window !== 'undefined' ? (window as any).gigya : null;
  const { loggedIn, login, logout, profile } = useCiamLogin(gigyaWindow);

  const shoppingCartCount = useSelector(shoppingBasketCountSelector);
  const orderHistoryUrl = useSelector(orderHistoryUrlSelector);
  const ciamEnabled = useSelector(ciamEnabledSelector);
  const ciamProfileUrl = useSelector(ciamProfileUrlSelector);
  const webshopEnabled = useSelector(webshopEnabledSelector);
  const webshopBasketPageUrl = useSelector(webshopBasketPageUrlSelector);
  const groupData = useSelector(groupDataSelector);

  return {
    shoppingCartCount,
    orderHistoryUrl,
    ciamEnabled,
    ciamProfileUrl,
    webshopEnabled,
    webshopBasketPageUrl,
    groupData,
    loggedIn,
    login,
    logout,
    profile,
  };
}
