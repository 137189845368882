import { MouseEvent, useContext } from 'react';
import { usePathname, useRouter } from 'next/navigation';

// components
import { NavItem } from './mobile-nav-item';

// styles
import { NavItemContainer } from 'mm/common/common.styles';

// constants
import { ANIMATION_DURATION } from 'mm/mega-menu-header';

// utils
import { MegaMenuContext } from 'mm/mega-menu-provider';
import { PathNode } from 'mm/hooks/use-navigation-items';
import { navigationTrackingAction } from 'components/Navigation/trackingActions';
import { useTracking } from 'utils/hooks/useTracking';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useNavigation } from '../navigation-provider';
import { useReactKeys } from 'mm/language-switch/use-react-keys';
import { useAnimation } from '../animation-provider';
import { useIsMobile } from 'components/App/SizeProvider';
import { decodingContent } from 'utils/decodingContent';

type MobileNavigationItemsProps = {
  pathNode?: PathNode;
};

export function MobileNavigationItems({ pathNode }: Readonly<MobileNavigationItemsProps>) {
  const { pushPathNode, navigation, thirdLevelNavigation } = useNavigation();
  const { setIsNavOpen } = useContext(MegaMenuContext);
  const { setAnimationType, setAnimationPhase } = useAnimation();
  const pathname = usePathname();
  const track = useTracking();
  const isMobile = useIsMobile();
  const translate = useTranslationFunction();
  let overviewItemAdded = false;

  function getProcessedChildren() {
    if (!pathNode?.children) return [];

    // Insert overview item if appropriate
    if (pathNode.depth > 1 && navigation?.url && isMobile) {
      const additionalNavItem: NavigationItem = {
        label: translate('web20_menu_tooverview'),
        url: navigation.url,
        isLast: false,
        originalIndex: pathNode.children.length,
        isOverview: true,
      };

      overviewItemAdded = true;

      return [additionalNavItem, ...pathNode.children];
    }

    return pathNode.children;
  }

  const processedChildren = getProcessedChildren();
  const childrenWithKeys = useReactKeys(processedChildren, ['ariaLabel', 'label', 'url']);

  if (!childrenWithKeys) return null;

  return (
    <>
      {childrenWithKeys.map((navItem) => {
        const isSelected = isNavItemSelected(pathNode!, navItem.originalIndex);
        const hasNoChildren = !navItem.children?.length;
        const notClickable =
          isSelected &&
          (hasNoChildren ||
            pathNode?.depth === 2 ||
            (pathNode?.depth === 1 && thirdLevelNavigation?.selectedIndex === undefined));

        return (
          <NavItemContainer className={navItem.isLast ? 'spacer' : ''} key={navItem.key}>
            <NavItem
              shallow={!navItem.url}
              href={navItem?.url || '/'}
              aria-label={navItem?.ariaLabel}
              selected={isSelected}
              onClick={getNavItemClickHandler(
                { ...navItem, depth: pathNode!.depth + 1 },
                navItem.originalIndex,
                navItem.url,
              )}
              notClickable={notClickable}
              leftIconUrl={pathNode!.depth > 0 && navItem.image}
              hasPictogram={Boolean(pathNode!.depth > 1 && navItem.image)}
              withPictogram={pathNode?.withPictogram}
              rightIconName={
                pathNode!.depth !== 2 &&
                navItem?.children &&
                navItem?.children?.length > 0 &&
                'chevronRight'
              }
              firstLevel={pathNode!.depth === 0}
              thirdLevel={pathNode!.depth === 2}
              isOverviewItem={navItem.isOverview}
            >
              <span className="label">{decodingContent(navItem.label ?? '')}</span>
            </NavItem>
          </NavItemContainer>
        );
      })}
    </>
  );

  function isNavItemSelected(pathNode: PathNode, index: number): boolean | undefined {
    // in mobile, navigation can be for all levels.
    // an item is only selected if index === navigation?.selectedIndex
    // and the level is active at the moment.

    if (!pathNode?.selectedIndex && overviewItemAdded) {
      // Check overview
      const navItem = childrenWithKeys?.find((ele) => ele.originalIndex === index);
      return navItem?.url === pathname;
    }

    return pathNode?.active && index === pathNode?.selectedIndex;
  }

  function handlePathNodeChildren(pathNode: PathNode, index: number) {
    pushPathNode({ ...pathNode }, index);
  }

  function getNavItemClickHandler(pathNode: PathNode, index: number, url: string) {
    return (e: MouseEvent<HTMLAnchorElement>) => {
      // If on mobile and item has children, don't preload page in the background
      if ((isMobile && pathNode.children) || (pathNode.depth < 2 && pathNode.children)) {
        e.preventDefault();
      }

      if (pathNode.children) {
        setTimeout(() => {
          handlePathNodeChildren(pathNode, index);
          setAnimationPhase(2);
        }, ANIMATION_DURATION);
      } else {
        setIsNavOpen(false);
      }

      if (url) {
        track.trackEvent(navigationTrackingAction(pathNode.label));
      }

      setAnimationPhase(1);
      setAnimationType('deeper');
    };
  }
}
