import styled, { css, useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { Container } from '@geberit/gdds';

// hooks
import { useNord } from 'utils/hooks/use-nord';
import { useThemeName } from 'utils/hooks/use-theme';
import { useIsDesktop } from '../App/SizeProvider';

// utils
import { styleConfig } from 'config';
import { THEME_NAMES } from 'themes';
import { showMegaMenuSelector } from 'utils/selectors/globalsSelectors';

// type
import type { LogoWrapperProps } from './types';

export const StyledContainer = styled(Container)(() => {
  return css`
    ${Container} &.logo-wrapper {
      padding: 0;
      margin-right: -24px;
    }
  `;
});

export const LogoLinkWrapper = styled(Link)(() => {
  const isNord = useNord();
  const theme = useTheme();

  return css`
    &::before {
      display: none;
    }

    height: 40px;
    margin-top: -2px;

    @media screen and (min-width: ${styleConfig.responsive.tabletMin}px) {
      height: 44px;
      margin-top: -2px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    z-index: ${isNord ? '0' : '50'};
    align-self: center;
    flex-shrink: 0;
    border: 1px solid transparent;
    outline-offset: 0px;

    ${isNord
      ? theme.mediaQueries.large
      : `@media (min-width: ${styleConfig.responsive.desktopMin}px)`} {
      align-self: ${isNord ? 'center' : 'flex-start'};
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:focus-visible {
      border: 1px solid ${theme.colors.primaryLight};
      outline: 1px solid ${theme.colors.primaryLight};
    }

    .image {
      height: 2.5rem;
      vertical-align: middle;
      width: ${isNord ? 'auto' : '9.0625rem'};

      #mega-menu & {
        @media screen and (max-width: ${styleConfig.responsive.tabletMax}px) {
          height: 1rem;
          width: auto;
        }
      }
    }

    .claim {
      color: ${theme.colors.cl11};
      font-size: 0.75rem;
      line-height: 0.625rem;
      word-break: initial;
      display: none;
      margin-top: -0.43rem;

      [data-preview-id] {
        display: block;
        min-height: unset;
      }

      #mega-menu & {
        display: block;
        margin-left: 0.5rem;
      }

      @media screen and (min-width: ${styleConfig.responsive.desktopMin}px) {
        display: block;
        margin-left: 0.3125rem;
        margin-top: -0.6rem;
      }
    }
  `;
});

export const LogoWrapper = styled.a(({ isSearchOpen, sticky }: Readonly<LogoWrapperProps>) => {
  const isNord = useNord();
  const isGroup = useThemeName() === THEME_NAMES.GDDS;
  const isDesktop = useIsDesktop();
  const showMegaMenu = useSelector(showMegaMenuSelector);
  const isDesktopMega = useIsDesktop({ gdds: true });
  const isNordAndMobile = isNord && !isDesktop;

  let shadowOpacity = 0.2;

  if (isGroup) {
    shadowOpacity = 0.4;
  }
  if (isNordAndMobile) {
    shadowOpacity = 0.0;
  }

  return css`
    &::before {
      display: none;
    }

    position: absolute;
    ${isNordAndMobile && 'top: 60px'};
    &&:not(.search-animated-logo) {
      ${isSearchOpen && isDesktopMega && 'display: none'};
    }
    transition: margin-top 0.3s ease;
    right: 0;
    overflow: hidden;
    ${showMegaMenu?.enabled && `z-index: 2`};
    ${(sticky || isNord) && 'z-index: -1'};

    .shadow {
      display: ${isNord || showMegaMenu?.enabled || isDesktop ? 'block' : ' none'};
      width: calc(100% + 1rem);
      height: 3.5rem;
      position: absolute;
      top: -3.5rem;
      right: -0.5rem;
      box-shadow: ${`0 3px 12px rgba(0, 0, 0, ${shadowOpacity})`};
    }
  `;
});
