import React from 'react';

// styles
import styles from './meta-navigation.module.scss';

// types
import type { MetaNavigationActionProps } from './types';

// components
import { Icon } from '../../Icon/Icon';

// hooks
import { useTracking } from 'utils/hooks/useTracking';
import Link from 'next/link';

function MetaNavigationAction({
  url,
  name,
  count,
  mobileTranslationWrapper,
  activeIcons,
  trackingAction,
  label,
  text,
}: Readonly<MetaNavigationActionProps>) {
  const track = useTracking();

  const trackOnClick = () => {
    if (typeof trackingAction === 'function') {
      track.trackEvent(trackingAction);
    }
  };

  return (
    <Link
      href={url ?? ''}
      className={`${styles.link} actions-${name} ${
        activeIcons.includes(name) ? styles.active : ''
      }`}
      onClick={trackOnClick}
      aria-label={label}
    >
      <Icon symbol={name} />
      {typeof text !== 'undefined' ? text : mobileTranslationWrapper(count, name)}
    </Link>
  );
}

export default MetaNavigationAction;
