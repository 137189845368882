// components
import { Navbar } from './navbar/navbar';
import { NavFlyout } from './nav-flyout';
import { AnimatedLogo } from 'components/Logo/animated-logo';

//styles
import { CSSVariables, MegaMenuContainer } from './common/common.styles';

// constants
export const ANIMATION_DURATION = 300;

// utils
import { MegaMenuProvider } from './mega-menu-provider';
import { NavigationProvider } from './mainnav/navigation-provider';
import { AnimationProvider } from './mainnav/animation-provider';
import { useClientSideRender } from 'utils/hooks/use-client-side-render';
import { useIsDesktop } from 'components/App/SizeProvider';

export function MegaMenuHeader() {
  const isClient = useClientSideRender();
  const isDesktop = useIsDesktop();

  return (
    <MegaMenuProvider>
      <MegaMenuContainer id="mega-menu">
        <NavigationProvider>
          <CSSVariables animationDuration={ANIMATION_DURATION}>
            <AnimationProvider>
              <Navbar />
              <NavFlyout />
            </AnimationProvider>
          </CSSVariables>
        </NavigationProvider>
        {isClient && isDesktop && <AnimatedLogo shadowDisabled />}
      </MegaMenuContainer>
    </MegaMenuProvider>
  );
}
